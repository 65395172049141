import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ScreenWithNavItems,
  StyledTable,
  RadioButtonGroup,
  ModalToElimate,
  CustomDimmer
} from "../components";
import { Input, Icon, TextArea, Form, Button } from "semantic-ui-react";
import { getAuthFromStorage } from "../redux/actions";
import {
  getAvailableStatus,
  getDriverEnterprises,
  saveDriverEnterprise,
  deleteDriverEnterprise,
} from "../services/ergonApi";
import { errorFields, Toast, Swal1, filterdata } from "../utils/common";
import "../customStyles.css";
import { ExcelButton } from '../components/ExcelButton'

const DriverEnterprise = () => {

  const history = useHistory();
  const { credentials } = useSelector((state) => state.auth);
  const [driverEnterprises, setDriverEnterprises] = useState([]);
  const [status, setStatus] = useState([]);
  const [alias, setAlias] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [contact, setContact] = useState("");
  const [telephone, setTelephone] = useState("");
  const [sendingData, setSendingData] = useState(false);
  const [idDriverEnterprise, setIdDriverEnterprise] = useState(null);
  const [notes, setNotes] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false);
  const [loadingDimmer, setLoadingDimmer] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (credentials == null) {
      dispatch(getAuthFromStorage());
    } else if (!credentials) {
      history.push("/");
    } else {
      setLoadingDimmer(true)
      fetchDriverEnterprises();
      fetchStatusTypes();

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);
  useEffect(() => {
    loadTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadDataOfTable]);

  async function fetchDriverEnterprises() {
    let response = await getDriverEnterprises(credentials.token);
    setLoadingDimmer(false)
    if (response.errorCode) {
      Swal1.fire({
        title: response.message,
      });
    } else {
      setDriverEnterprises(response);
    }
  }

  async function fetchStatusTypes() {
    let statusTypesResponse = await getAvailableStatus(credentials.token);
    if (statusTypesResponse.errorCode) {
      Swal1.fire({
        title: statusTypesResponse.message,
      });
    } else {
      setStatus(statusTypesResponse);
      setSelectedStatus(statusTypesResponse[0]);
    }
  }

  async function handleButtonClick() {
    if (
      alias !== "" &&
      alias != null &&
      businessName !== "" &&
      businessName != null &&
      emails.length > 0
    ) {
      setSendingData(true);
      let obj = {
        id: idDriverEnterprise,
        alias: alias,
        razonSocial: businessName,
        nombreContacto: contact,
        correoContacto: emails,
        telefonoContacto: telephone,
        estado: selectedStatus.id,
        notas: notes,
      };
      let response = await saveDriverEnterprise(credentials.token, obj);
      if (!response.errorCode) {
        Toast.fire({
          title: response.message,
        });
      } else {
        Swal1.fire({
          title: response.message,
        });
      }
      await fetchDriverEnterprises();
      setEditValues({
        id: null,
        notas: "",
        alias: "",
        correoContacto: "",
        telefonoContacto: "",
        nombreContacto: "",
        estado: status[0],
        razonSocial: "",
      });
      setSendingData(false);
    } else {
      Swal1.fire({
        title: "Falta Información",
      });
      if (alias === "" || alias == null) {
        setAlias(null);
      }
      if (businessName === "" || businessName == null) {
        setBusinessName(null);
      }
      if (emails.length > 0) {
        setEmails([]);
      }
    }
  }

  async function handleDeleteButtonClick() {
    setSendingData(true);
    let response = await deleteDriverEnterprise(
      credentials.token,
      idDriverEnterprise
    );
    setShowDeleteConfirmation(false);
    if (!response.errorCode) {
      Toast.fire({
        title: response.message,
      });
    } else {
      Swal1.fire({
        title: response.message,
      });
    }

    await fetchDriverEnterprises();
    cleanData();
    setSendingData(false);
  }
  function renderEmails(emails) {
    if (emails.length > 0) {
      return emails.map((email) => (
        <div style={{ minWidth: "50%" }}>
          <Input
            readonly
            icon={
              <Icon
                name="delete"
                link
                onClick={() => setEmails(emails.filter((e) => e !== email))}
              />
            }
            value={email}
            style={{ minWidth: "100%" }}
          />
        </div>
      ));
    }
  }
  function setEditValues(data) {
    setIdDriverEnterprise(data.id);
    setNotes(data.notas);
    setAlias(data.alias);
    setEmails(data.correoContacto);
    setTelephone(data.telefonoContacto);
    setContact(data.nombreContacto);
    setSelectedStatus(data.estado);
    setBusinessName(data.razonSocial);
  }
  const cleanData = () => {
    setAlias("");
    setBusinessName("");
    setContact("");
    setEmails([]);
    setTelephone("");
    setNotes("");
    setIdDriverEnterprise(null);
    setCurrentEmail("");
  };
  function handleChangealiasvalue(value) {
    setAlias(value);
  }
  function handleChangebusinessNamevalue(value) {
    setBusinessName(value);
  }
  function handleFilterData(data, e) {
    setDriverEnterprises(filterdata(data, e, reloadDataOfTable));
    setReloadDataOfTable(!reloadDataOfTable);
  }
  function loadTable() {
    return (
      <StyledTable
        headers={[
          { path: "alias", title: "Alias" },
          { path: "nombreContacto", title: "Nombre Contacto" },
          { path: "estado.nombre", title: "Estado" },
        ]}
        searchBy={["alias", "nombreContacto","estado.nombre"]}
        headerSearchBy={["Alias", "Nombre Contacto","Estado"]}
        data={driverEnterprises}
        handleOnClickRow={(data) => setEditValues(data)}
        handleOnClickHeader={(e) => handleFilterData(driverEnterprises, e)}
      />
    );
  }
  return (
    <ScreenWithNavItems user={credentials ? credentials.user : null}>
      <span style={styles.titleText}>Transportistas</span>
      <div style={styles.mainContainer}>
        <ModalToElimate
          title="Confirmación para eliminar transportista"
          textContent="Una vez eliminada la información no se podrá recuperar. ¿ Esta seguro que desea eliminar?"
          showModal={showDeleteConfirmation}
          handleOnClickCancel={() => setShowDeleteConfirmation(false)}
          handleOnClickConfirm={() => handleDeleteButtonClick()}
        />
      <CustomDimmer visible={loadingDimmer}/>
        <div style={styles.formContainer}>
          <Form size="mini">
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Alias: </span>
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <div style={{ display: "flex", width: "45%" }}>
                  <Input
                    value={alias}
                    onChange={(e, { value }) => handleChangealiasvalue(value)}
                    style={{ width: "100%", margin: 0, background: "null" }}
                    error={errorFields(alias)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "50%",
                    marginLeft: "5%",
                    justifyContent: "flex-end",
                  }}
                >
                  <span style={styles.subtitleTextEstado}> Estado: </span>
                  <RadioButtonGroup
                    options={status}
                    selectedValue={selectedStatus}
                    onSelectoption={(value) => setSelectedStatus(value)}
                    labelKey="nombre"
                  />
                </div>
              </div>
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Razón Social: </span>
              <Input
                error={errorFields(businessName)}
                id="businessNameid"
                value={businessName}
                onChange={(e, { value }) =>
                  handleChangebusinessNamevalue(value)
                }
                style={{ width: "100%", margin: 0 }}
              />
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Contacto Principal: </span>
              <Input
                value={contact}
                onChange={(e, { value }) => setContact(value)}
                style={{ width: "100%", margin: 0 }}
              />
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Correos: </span>
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {renderEmails(emails)}
                <Input
                  value={currentEmail}
                  onChange={(e, { value }) => setCurrentEmail(value)}
                  icon={
                    <Icon
                      name="add"
                      onClick={() => {
                        setEmails([...emails, currentEmail]);
                        setCurrentEmail("");
                      }}
                      link
                    />
                  }
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Telefono: </span>
              <Input
                value={telephone}
                onChange={(e, { value }) => setTelephone(value)}
                style={{ width: "100%", margin: 0 }}
              />
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Notas : </span>
              <div style={{ width: "100%" }}>
                <TextArea
                  value={notes}
                  onChange={(event) => setNotes(event.target.value)}
                  style={{ width: "100%", margin: 0 }}
                />
              </div>
            </div>
            <div style={styles.buttonContainer}>
              {idDriverEnterprise ? (
                <Button
                  color="grey"
                  loading={sendingData}
                  style={{ marginRight: 10, fontSize: 12 }}
                  onClick={() => cleanData()}
                >
                  Cancelar cambios
                </Button>
              ) : (
                <></>
              )}
              {idDriverEnterprise ? (
                <Button
                  color="red"
                  loading={sendingData}
                  style={{ marginRight: 10, fontSize: 12 }}
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Eliminar
                </Button>
              ) : (
                <></>
              )}

              <Button
                primary
                loading={sendingData}
                style={{ margin: 0, fontSize: 12 }}
                onClick={() => handleButtonClick()}
              >
                {" "}
                {idDriverEnterprise ? "Guardar cambios" : "Guardar"}
              </Button>
            </div>
          </Form>
        </div>

        <div style={ styles.tableContainer }>
          <div style={ styles.companiesExcelButton } >
            <ExcelButton
              endpoint='/transportista/download_excel'
              fileName='Transportistas'
            />
          </div>
          <div style={ styles.table }>
            { loadTable() }
          </div>
        </div>
      </div>
    </ScreenWithNavItems>
  );
};

const styles = {
  mainContainer: {
    display: "flex",
    height: "90%",
    width: "100%",
    padding: 10,
    justifyContent: "space-between",
  },
  titleText: {
    fontSize: "3rem",
    fontWeight: "400",
  },
  formContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    width: "45%",
    padding: 10,
    borderRadius: 5,
  },
  formLine: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  subtitleText: {
    width: "30%",
    fontSize: 12,
    textAlign: "right",
    paddingRight: 5,
  },
  subtitleTextEstado: {
    width: "30%",
    fontSize: 12,
    textAlign: "right",
    paddingRight: 5,
  },
  tableContainer: {
    display: "flex",
    flexDirection: 'column',
    height: "90%",
    width: "50%",
    padding: 10
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 10,
  },
  companiesExcelButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 5
  },
  table: {
    display: "flex",
    overflow: "auto"
  }
};

export { DriverEnterprise };
