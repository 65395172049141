import React, { createContext, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getAuthFromStorage } from '../redux/actions'
import { ScreenWithNavItems } from '../components'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const dispatch        = useDispatch()
  const history         = useHistory()

  const getUser    = () => credentials?.user || null
  const getActions = () => credentials?.actions ||  []

  useEffect(() => {
    if (credentials == null)
      dispatch(getAuthFromStorage())
    else if (!credentials)
      history.push("/")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ credentials ])

  const context = {
    credentials
  }

  return (
    <AuthContext.Provider value={ context }>
      <ScreenWithNavItems user={ getUser() } userActions={ getActions() }>
        { children }
      </ScreenWithNavItems>
    </AuthContext.Provider>
  )
}

const useAuthContext = () => useContext(AuthContext)

export { AuthProvider, useAuthContext }
