import React, { useEffect } from 'react'
import { createContext, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  deletteAnObject,
  getGeneralInfo,
  saveAnObject
} from '../services/ergonApi'
import { CustomDimmer } from '../components'
import { AuthProvider } from './authContext'
import { now } from '../helpers/date'
import { responseHandler, showErrorAlert } from '../helpers/notifications'

const OrderContext = createContext()

const OrderProvider = ({ children }) => {
  const { credentials } = useSelector(state => state.auth)

  useEffect(() => {
    if (credentials) load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState(null)
  const [isWorking, setIsWorking] = useState(false)
  const [plants, setPlants] = useState([])
  const [clients, setClients] = useState([])
  const [products, setProducts] = useState([])
  const [destinations, setDestinations] = useState([])
  const [legalNames, setLegalNames] = useState([])
  const [measureUnits, setMeasureUnits] = useState([])
  const [detailsLimit, setDetailsLimit] = useState(0)
  const [clientUnits, setClientUnits] = useState([])
  const [selectedFilterPlant, setSelectedFilterPlant] = useState(null)
  const [selectedFilterQuantity, setSelectedFilterQuantity] = useState(null)
  const [loadingDimmer, setLoadingDimmer] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [showConfirmEdit, setShowConfirmEdit] = useState(false)
  const [editionAllowed, setEditionAllowed] = useState(false)
  const [action, setAction] = useState(() => {})
  const [showConfirmDestroy, setShowConfirmDestroy] = useState(false)
  const [showConfirmUnassign, setShowConfirmUnassign] = useState(false)
  const [hasClientUnit, setHasClientUnit] = useState(false)
  const [showClientUnitModal, setShowClientUnitModal] = useState(false)
  const [isUnasignable, setIsUnasignable] = useState(false)

  const [folio,                 setFolio]                 = useState('')
  const [fechaPedido,           setFechaPedido]           = useState(now())
  const [fechaSolicitud,        setFechaSolicitud]        = useState(now())
  const [fechaCarga,            setFechaCarga]            = useState(now())
  const [fechaRequerida,        setFechaRequerida]        = useState(now())
  const [plantaId,              setPlantaId]              = useState(0)
  const [externo,               setExterno]               = useState(true)
  const [pendienteLiberar,      setPendienteLiberar]      = useState(false)
  const [asignado,              setAsignado]              = useState(false)
  const [clienteId,             setClienteId]             = useState(0)
  const [programado,            setProgramado]            = useState(false)
  const [caso,                  setCaso]                  = useState(1)
  const [temperaturaCompromiso, setTemperaturaCompromiso] = useState(0)
  const [contacto,              setContacto]              = useState('')
  const [telefonoContacto,      setTelefonoContacto]      = useState('')
  const [notas,                 setNotas]                 = useState('')
  const [unidadCliente,         setUnidadCliente]         = useState(null)
  const [detalles,              setDetalles]              = useState([])

  useEffect(() => {
    if (!credentials && !loadingDimmer) return
    searchOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plants])

  const load = async () => {
    setLoadingDimmer(true)
    setPlants(await getPlants())
    setClients(await getClients())
    setProducts(await getProducts())
    setMeasureUnits(await getMeasureUnits())
    setDetailsLimit(await getDetailsLimit())
    setClientUnits(await getClientUnits())
    setSelectedFilterQuantity(200)
    setLoadingDimmer(false)
  }

  const getUserPlant = () => {
    if (credentials.user.nivel.id !== 2) return null
    return credentials.user.planta?.id
  }

  const getOrders = async () => {
    const params = {
      plantId: orderScreen.selectedFilterPlant || getUserPlant(),
      records: orderScreen.selectedFilterQuantity || 200
    }
    return await getGeneralInfo(credentials.token, '/order', params)
  }

  const getPlants = async () => (
    await getGeneralInfo(credentials.token, '/order/plants')
  )

  const getClients = async () => (
    await getGeneralInfo(credentials.token, '/order/clients')
  )

  const getProducts = async () => (
    await getGeneralInfo(credentials.token, '/order/products')
  )

  const getMeasureUnits = async () => (
    await getGeneralInfo(credentials.token, '/order/measureUnits')
  )

  const getDetailsLimit = async () => (
    await getGeneralInfo(credentials.token, '/order/detailsLimit')
  )

  const getClientUnits = async () => (
    await getGeneralInfo(credentials.token, '/order/clientUnits')
  )

  const getDestinations = async (clientId, isPlant) => {
    const params = {
      isPlant: isPlant,
      id: clientId
    }
    return await getGeneralInfo(credentials.token, '/order/destinations', params)
  }

  const getLegalNames = async (clientId, isPlant) => {
    const params = {
      isPlant: isPlant,
      id: clientId
    }
    return await getGeneralInfo(credentials.token, '/order/legalNames', params)
  }

  const loadDestination = async (clientId, isPlant) => {
    setDestinations(await getDestinations(clientId, isPlant))
  }

  const loadLegalNames = async (clientId, isPlant) => {
    setLegalNames(await getLegalNames(clientId, isPlant))
  }

  const getOrder = async id => {
    clean()
    setIsWorking(true)
    const order = await getGeneralInfo(credentials.token, `/order/${id}`)
    setOrder(order)
    setFolio(order.folio)
    setFechaPedido(order.fechaPedido)
    setFechaSolicitud(order.fechaSolicitud || now())
    setFechaCarga(order.fechaCarga)
    setFechaRequerida(order.fechaRequerida)
    setPlantaId(order.plantaId)
    setClienteId(order.clienteId)
    setExterno(order.externo === 1)
    setPendienteLiberar(order.estado === 9)
    setAsignado(![1, 9].includes(order.estado))
    setIsUnasignable([4, 5].includes(order.estado))
    setProgramado(order.programado === 1)
    setCaso(order.caso)
    setTemperaturaCompromiso(order.temperaturaCompromiso)
    setContacto(order.contacto || '')
    setTelefonoContacto(order.telefonoContacto || '')
    setNotas(order.notas || '')
    setUnidadCliente(order.unidadCliente || null)
    setDetalles(order.detalles)
    setHasClientUnit(!!order.unidadCliente)
    loadDestination(order.clienteId, order.externo !== 1)
    loadLegalNames(order.clienteId, order.externo !== 1)
    setIsEditing(true)
    setIsWorking(false)
  }

  const searchOrders = async () => (
    setOrders(await getOrders())
  )

  const getDetailsParams = () => detalles.map(d => ({
    productoId:          d.productoId,
    destinoId:           d.destinoId,
    razonSocial:         d.razonSocial,
    precioUnitario:      d.precioUnitario,
    cantidad:            d.cantidad,
    unidadMedida:        d.unidadMedida,
    remision:            d.remision,
    cantidadRealCargada: d.cantidadReal
  }))

  const getSaveParams = () => ({
    plantaId,
    clienteId,
    fechaPedido,
    fechaSolicitud,
    fechaRequerida,
    fechaCarga,
    caso,
    temperaturaCompromiso,
    contacto,
    telefonoContacto,
    notas,
    programado,
    externo,
    pendienteLiberar,
    detalles: getDetailsParams(),
    unidadCliente
  })

  const save = async () => {
    if (!plantaId)
      return showErrorAlert('Debes seleccionar una planta')
    if (!clienteId && externo)
      return showErrorAlert('Debes seleccionar un cliente')
    if (!clienteId && !externo)
      return showErrorAlert('Debes seleccionar una planta de destino')
    if (!detalles.length)
      return showErrorAlert('El pedido debe de tener por lo menos un producto asignado')
    setIsWorking(true)
    const params = getSaveParams()
    const response = await saveAnObject(credentials.token, '/order/', params)
    responseHandler(response)
    if (!response.error) {
      clean()
      load()
    }
    setIsWorking(false)
  }

  const edit = async () => {
    if (!clienteId && externo)
      return showErrorAlert('Debes seleccionar un cliente')
    if (!clienteId && !externo)
      return showErrorAlert('Debes seleccionar una planta de destino')
    if (!detalles.length)
      return showErrorAlert('El pedido debe de tener por lo menos un producto asignado')
    setIsWorking(true)
    const params = { ...getSaveParams(), folio, id: folio }
    const response = await saveAnObject(credentials.token, '/order/', params)
    responseHandler(response)
    if (!response.error) {
      clean()
      load()
    }
    setIsWorking(false)
  }

  const destroy = async () => {
    setIsWorking(true)
    const response = await deletteAnObject(credentials.token, '/order/', folio)
    responseHandler(response)
    if (!response.error) {
      clean()
      load()
    }
    setIsWorking(false)
  }

  const unassign = async () => {
    setIsWorking(true)
    const url = '/pedido/cancelarConfirmacion/'
    const response = await deletteAnObject(credentials.token, url, folio)
    responseHandler(response)
    if (!response.error) {
      clean()
      load()
    }
    setIsWorking(false)
  }

  const isPlantUser = () => credentials?.user.nivel.id === 2

  const handleClean = () => {
    if (isPlantUser()) return plantaId
    return 0
  }

  const clean = () => {
    setFolio('')
    setFechaPedido(now())
    setFechaSolicitud(now())
    setFechaCarga(now())
    setFechaRequerida(now())
    setPlantaId(handleClean())
    setExterno(true)
    setPendienteLiberar(false)
    setAsignado(false)
    setClienteId(0)
    setProgramado(false)
    setCaso(1)
    setTemperaturaCompromiso(0)
    setContacto('')
    setTelefonoContacto('')
    setNotas('')
    setUnidadCliente(null)
    setDetalles([])
    setHasClientUnit(false)
    setEditionAllowed(false)
    setAction(() => {})
    setShowConfirmEdit(false)
    setShowConfirmDestroy(false)
    setShowConfirmUnassign(false)
    setIsEditing(false)
  }

  const orderScreen = {
    credentials,
    getOrder,
    orders,                 setOrders,
    order,                  setOrder,
    selectedFilterPlant,    setSelectedFilterPlant,
    selectedFilterQuantity, setSelectedFilterQuantity,
    showConfirmEdit,        setShowConfirmEdit,
    editionAllowed,         setEditionAllowed,
    action,                 setAction,
    showConfirmDestroy,     setShowConfirmDestroy,
    showConfirmUnassign,    setShowConfirmUnassign,
    hasClientUnit,          setHasClientUnit,
    showClientUnitModal,    setShowClientUnitModal,
    plants,
    clients,
    products,
    destinations,
    legalNames,
    measureUnits,
    detailsLimit,
    clientUnits,
    isWorking,
    searchOrders,
    isEditing,
    isUnasignable,
    loadDestination,
    loadLegalNames,
    clean,
    save,
    edit,
    destroy,
    unassign,
    folio,                 setFolio,
    fechaPedido,           setFechaPedido,
    fechaSolicitud,        setFechaSolicitud,
    fechaCarga,            setFechaCarga,
    fechaRequerida,        setFechaRequerida,
    plantaId,              setPlantaId,
    externo,               setExterno,
    pendienteLiberar,      setPendienteLiberar,
    asignado,              setAsignado,
    clienteId,             setClienteId,
    programado,            setProgramado,
    caso,                  setCaso,
    temperaturaCompromiso, setTemperaturaCompromiso,
    contacto,              setContacto,
    telefonoContacto,      setTelefonoContacto,
    notas,                 setNotas,
    detalles,              setDetalles,
    unidadCliente,         setUnidadCliente
  }

  return (
    <AuthProvider>
      <OrderContext.Provider value={ orderScreen }>
        <CustomDimmer visible={ loadingDimmer } />
        { children }
      </OrderContext.Provider>
    </AuthProvider>
  )
}

const useOrderContext = () => useContext(OrderContext)

export { OrderProvider, useOrderContext }
