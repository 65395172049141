import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const CapacityLabel = () => {
  const context = useOrdersInTransitContext()

  const numberFormat = text => {
    if (text.length <= 3) return text
    const decimals = text.toString().split('.')[1]
    return text
      .toString()
      .split('.')[0]
      .split('')
      .reverse()
      .join('')
      .replace(/.{3}/g, "$& ")
      .split('')
      .reverse()
      .join('') +
      (decimals ? `.${decimals}` : '')
  }

  const getMeasureUnit = () => context.orderDetails.info.unidadMedida || ''

  const buildCapacity = capacity => {
    if (!capacity) return null
    return `${numberFormat(capacity)} ${getMeasureUnit()}`
  }

  const getLabel = () => (
    buildCapacity(context.orderDetails.info.capacidad) || 'Sin asignar'
  )

  return (
    <div>
      <div>Capacidad:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { CapacityLabel }
