import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ScreenWithNavItems, CustomDimmer } from '../components'
import { Dropdown, Button } from 'semantic-ui-react'
import { getAuthFromStorage } from '../redux/actions'
import { getGeneralInfo, saveVehicle } from '../services/ergonApi'
import { Toast, Swal1 } from '../utils/common'

import '../customStyles.css'

const CheckUnitStatus = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { credentials } = useSelector((state) => state.auth)

  const [loadingDimmer,           setLoadingDimmer]           = useState(true)
  const [unitsDropdown,           setUnitsDropdown]           = useState([])
  const [selectedUnit,            setSelectedUnit]            = useState([])
  const [,                        setUnitsAndOrders]          = useState([])
  const [buttonChangeToAvailable, setButtonChangeToAvailable] = useState(false)
  const [buttonChangeToAssigned,  setButtonChangeToAssigned]  = useState(false)

  useEffect(() => {
    if (credentials == null)
      dispatch(getAuthFromStorage())
    else if (!credentials)
      history.push("/")
    else
      fetchInformationFromAPI()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const fetchInformationFromAPI = async () => {
    let [vehiclesResponse, ordersResponse] = await Promise.all([
      getGeneralInfo(credentials.token, '/unidad'),
      getGeneralInfo(credentials.token, '/pedido')
    ])
    if (vehiclesResponse.errorCode || ordersResponse.errorCode) {
      if (vehiclesResponse.errorCode)
        Swal1.fire({ title: vehiclesResponse.message })
      if (ordersResponse.errorCode)
        Swal1.fire({ title: ordersResponse.message })
    }
    else
      createObjectWithUnitsAndOrders(vehiclesResponse, ordersResponse)
    setLoadingDimmer(false)
  }

  const createObjectWithUnitsAndOrders = (vehiclesResponse, ordersResponse) => {
    let auxOrders = []
    let latestOrder = {}
    let auxUnitsAndOrdersArray = []
    let unitsAndOrdersFormattedArray = []
    vehiclesResponse.map(v => {
      ordersResponse.map(o => {
        if (o?.unidadAsignada?.id === v.id)
          auxOrders.push(o)
        return null
      })
      if (auxOrders.length) {
        auxOrders.map((o, i) => {
          if (i === 0)
            latestOrder = o
          else if (o.folio > latestOrder.folio)
            latestOrder = o
          return null
        })
      }
      auxUnitsAndOrdersArray.push({
        unitID: v.id,
        unitName: v.economico,
        unitPlates: v.placas,
        tankPlates: v.placasTanque,
        unitStatus: v.estado,
        order: latestOrder,
      })
      auxOrders = []
      latestOrder = {}
      return null
    })
    auxUnitsAndOrdersArray.map(x => {
      unitsAndOrdersFormattedArray.push({
        key: x.unitID,
        text: x.unitName,
        value: x,
      })
      return null
    })
    setUnitsDropdown(unitsAndOrdersFormattedArray)
    setUnitsAndOrders(auxUnitsAndOrdersArray)
  }

  const isReadyToBeAvailable = order => [1, 6].includes(order.estado.id)
  const isReadyToBeAsigned = order => [5, 4, 3].includes(order.estado.id)

  const validateUnitAndOrderToShowButtons = data => {
    if (
      data.order &&
      Object.keys(data.order).length === 0 &&
      Object.getPrototypeOf(data.order) === Object.prototype
    ) {
      setButtonChangeToAvailable(data.unitStatus.id !== 1)
    } 
    else {
      if (!(data.order.estado && data.unitStatus))
        return console.log('order without status')
      setButtonChangeToAvailable(
        data.unitStatus.id !== 1 && isReadyToBeAvailable(data.order)
      )
      setButtonChangeToAssigned(
        data.unitStatus.id !== 2 && isReadyToBeAsigned(data.order)
      )
    }
  }

  const handleChangeUnit = data => {
    setSelectedUnit(data)
    validateUnitAndOrderToShowButtons(data)
  }

  const handleUnitToAssigned = async () => {
    const params = { estado: 2, id: selectedUnit.unitID }
    let response = await saveVehicle(credentials.token, params)
    if (response.errorCode)
      Swal1.fire({ title: response.message })
    else {
      Toast.fire({ title: 'Se asignó la unidad con éxito' })
      setSelectedUnit(null)
      setButtonChangeToAssigned(false)
      await fetchInformationFromAPI()
    }
  }

  const handleUnitToAvalible = async () => {
    const params = { estado: 1, id: selectedUnit.unitID }
    const response = await saveVehicle(credentials.token, params)
    if (response.errorCode)
      Swal1.fire({ title: response.message })
    else {
      Toast.fire({ title: 'Se liberó la unidad con éxito' })
      setSelectedUnit(null)
      setButtonChangeToAvailable(false)
      await fetchInformationFromAPI()
    }
  }

  const getFolioFormat = () => (
    selectedUnit?.order?.folio ? `Folio - ${selectedUnit.order.folio} ` : ''
  )

  return (
    <ScreenWithNavItems user={credentials ? credentials.user : null}>
        <CustomDimmer visible={loadingDimmer} />
      <div className="screen-check-unit-status">
        <div className="form-container">
          <div className="header-title">
            <span className="title-text">Consulta estatus de unidad</span>
          </div>
          <div className="form-line">
            <span className="label-key">Selecciona unidad:</span>
            <Dropdown
              placeholder="Seleccione una unidad"
              fluid
              selection
              search
              options={ unitsDropdown }
              value={ selectedUnit }
              onChange={ (e, data) => handleChangeUnit(data.value) }
            />
          </div>
          <div className="form-line">
            <span className="label-key">Placas unidad:</span>
            <div className="fake-input">
              { selectedUnit?.unitPlates }
            </div>
            <span className="label-key">Placas tanque:</span>
            <div className="fake-input">
              { selectedUnit?.tankPlates }
            </div>
          </div>
          <div className="form-line">
            <span className="label-key">Pedido actual:</span>
            <div className="fake-input">
              { getFolioFormat() }
            </div>
          </div>
          <div className="form-line">
            <span className="label-key">Estatus actual:</span>
            <div className="fake-input">
              { selectedUnit?.unitStatus?.nombre }
            </div>
            <span className="label-key">Estatus pedido:</span>
            <div className="fake-input">
              { selectedUnit?.order?.estado?.nombre }
            </div>
          </div>
          <footer>
            {
              buttonChangeToAvailable ? 
                <Button primary onClick={ () => handleUnitToAvalible() }>
                  Cambiar a disponible
                </Button> : 
                <></>
            }
            {
              buttonChangeToAssigned ? 
                <Button primary onClick={ () => handleUnitToAssigned() }>
                  Cambiar a asignada
                </Button> : 
                <></>
            }
          </footer>
        </div>
      </div>
    </ScreenWithNavItems>
  )
}

export { CheckUnitStatus }
