import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ScreenWithNavItems,
  StyledTable,
  RadioButtonGroup,
  ModalToElimate,
  CustomDimmer
} from "../components";
import {
  Input,
  Dropdown,
  TextArea,
  Form,
  Button,
  Icon,
} from "semantic-ui-react";
import { getAuthFromStorage } from "../redux/actions";
import {
  getAvailableStatus,
  getClients,
  saveClient,
  deleteClient,
  getGeneralInfo,
} from "../services/ergonApi";
import "../customStyles.css";
import {
  parseArrayObjectsToDropdownArray,
  Toast,
  Swal1,
  filterdata,
} from "../utils/common";
import { Alert } from "react-bootstrap";
import resources from "../resources";
import { ExcelButton } from "../components/ExcelButton";
const emptyClient = {
  id: null,
  alias: "",
  razonSocial: [],
  contacto: "",
  telefono: "",
  correoElectronico: "",
  estado: null,
  notas: "",
  destinos: [],
};

const emptyClientDestination = {
  nombreDestino: "",
  geocerca: null,
  idGeocerca: "",
};

const Client = () => {

  const history = useHistory();
  const { credentials } = useSelector((state) => state.auth);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(emptyClient);
  const [geofences, setGeofences] = useState([]);
  const [status, setStatus] = useState([]);
  const [sendingData, setSendingData] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showModalAdvise, setShowModalAdvise] = useState(false);
  const [showDeleteDetailsConfirmation, setShowDeleteDetailsConfirmation] = useState(false);
  const [auxArrayDes, setAuxArrayDes] = useState([]);
  const [loadingDimmer, setLoadingDimmer] = useState(true);
  const [clientDestination, setClientDestination] = useState(
    emptyClientDestination
  );

  const [reloadDataOfTable, setReloadDataOfTable] = useState(false);
  const [currentSocial, setCurrentSocial] = useState("");
  const [allSocials, setAllSocials] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {

    if (credentials == null) {

      dispatch(getAuthFromStorage());
    } else if (!credentials) {
      history.push("/");
    } else {
      setLoadingDimmer(true)
      fetchClients();
      fetchGeofences();
      fetchStatusTypes();

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  useEffect(() => {
    renderSocials(client.razonSocial);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSocials]);

  async function fetchClients() {
    let response = await getClients(credentials.token);
    if (response.errorCode) {
      Swal1.fire({
        title: response.message,
      });
    } else {
      setClients(response);
    }
    setLoadingDimmer(false)
  }
  async function fetchGeofences() {
    let geodfenceResponse = await getGeneralInfo(
      credentials.token,
      "/geocerca",
      { estado: 1, tipo: 1 }
    );
    if (geodfenceResponse.errorCode) {
      Swal1.fire({
        title: geodfenceResponse.message,
      });
    } else {
      geodfenceResponse = parseArrayObjectsToDropdownArray(
        geodfenceResponse,
        "nombre"
      );
      setGeofences(geodfenceResponse);
    }
  }
  async function fetchStatusTypes() {
    let statusTypesResponse = await getAvailableStatus(credentials.token);
    if (statusTypesResponse.errorCode) {
      Swal1.fire({
        title: statusTypesResponse.message,
      });
    } else {
      setStatus(statusTypesResponse);
      handleOnChange("estado", statusTypesResponse[0]);
    }
  }
  async function handleOnSelectClient(data) {
    setShowModalAdvise(false)
    let response = await getClients(credentials.token, data.id);
    if (response.errorCode) {
      Swal1.fire({
        title: response.message,
      });
    } else {
      setEditValues(response);
      setAuxArrayDes(response.destinos)
    }
  }
  async function handleButtonClick() {

    setShowDeleteDetailsConfirmation(false)
    if (!client.destinos.length) {
      client.destinos.push({ idGeocerca: 664, nombreDestino: "Libre a bordo en planta", nombreGeocerca: "Libre a bordo en planta" })
    }
    let auxGeocercas = client.destinos.map((obj) => {
      return {
        nombreDestino: obj.nombreDestino,
        geocerca: obj.idGeocerca,
      };
    });

    let aux = { ...client, destinos: auxGeocercas, estado: client.estado.id };
    setSendingData(true);
    let response = await saveClient(credentials.token, aux);
    if (response.errorCode) {
      Swal1.fire({
        title: response.message,
      });
    }
    if (!response.errorCode) {
      Toast.fire({
        title: response.message,
      });

    }
    await fetchClients();
    setClient({ ...emptyClient, estado: status[0] });
    setCurrentSocial("");
    setSendingData(false);
    cleanForms();
    setShowModalAdvise(false)
  }
  async function handleDeleteButtonClick() {
    setSendingData(true);
    let response = await deleteClient(credentials.token, client.id);
    setShowDeleteConfirmation(false);
    if (response.errorCode) {
      Swal1.fire({
        title: response.message,
      });
    }
    if (!response.errorCode) {
      Toast.fire({
        title: response.message,
      });
    }
    await fetchClients();
    setClient({ ...emptyClient, estado: status[0] });
    setSendingData(false);
    setCurrentSocial("");
    cleanForms();
  }
  function setEditValues(data) {
    setClient(data);
  }
  function handleOnChangeNewClientDestination(key, value) {
    let aux = { ...clientDestination, [key]: value };
    if (key === "geocerca") {
      aux.nombreDestino = value.nombre;
    }
    setClientDestination(aux);
  }
  function addNewDestinationToClient() {
    let auxDestinos = client.destinos;
    if (!clientDestination.nombreDestino || !clientDestination.geocerca) {
      Swal1.fire({
        title: "Debes ingresar los datos del destino nuevo",
      });
    } else {
      auxDestinos.push({
        nombreDestino: clientDestination.nombreDestino,
        idGeocerca: clientDestination.geocerca.id,
        nombreGeocerca: clientDestination.geocerca.nombre,
      });
      let aux = { ...client, destinos: auxDestinos };
      setClient(aux);
      setClientDestination(emptyClientDestination);
    }
  }
  function handleOnChange(key, value) {
    let aux = { ...client, [key]: value };
    setClient(aux);
  }
  function onDeleteDestination(obj) {
    setShowModalAdvise(true)
    let aux = client.destinos.filter((d) => {
      if (d.idDestino !== obj.idDestino) {
        return d;
      }
      return false
    });
    setClient({ ...client, destinos: aux });
  }

  const cleanForms = () => {
    setAuxArrayDes([])
    setClient({ ...emptyClient, estado: status[0] });
    setCurrentSocial("");
    setClientDestination(emptyClientDestination);
    setShowModalAdvise(false)
  };
  function handleFilterData(data, e) {
    setClients(filterdata(data, e, reloadDataOfTable));
    setReloadDataOfTable(!reloadDataOfTable);
  }
  function handleFilterDataSecondaryTable(data, e) {
    let aux = filterdata(data, e, reloadDataOfTable);
    client.destinos = aux;
    setReloadDataOfTable(!reloadDataOfTable);
  }
  function loadTable() {
    return (
      <StyledTable
        headers={[
          { path: "alias", title: "Alias Cliente" },
          { path: "razonSocial", title: "Razón Social" },
          { path: "telefono", title: "Teléfono" },
          { path: "totalDestinos", title: "Destinos Configurados" },
        ]}
        searchBy={["alias", "razonSocial","telefono","totalDestinos"]}
        headerSearchBy={["Alias", "Razón Social","Teléfono","Destinos Configurados"]}
        data={clients}
        handleOnClickRow={(data) => handleOnSelectClient(data)}
        handleOnClickHeader={(e) => handleFilterData(clients, e)}
      />
    );
  }
  function renderSocials(values) {
    if (!values.length) return null
    return values.map(value => (
      <div style={{ minWidth: "50%" }}>
        <Input
          readonly
          icon={
            <Icon
              name="delete"
              link
              onClick={() => {
                let aux = values.filter((e) => e !== value);
                handleOnChange("razonSocial", aux);
                setAllSocials(!allSocials);
              }}
            />
          }
          value={value}
          style={{ minWidth: "100%" }}
        />
      </div>
    ));
  }
  function checkSaveData() {
    if(showModalAdvise){
      setShowDeleteDetailsConfirmation(true)
    }
    else{
      handleButtonClick()
    }
  }
  function HandleSetShowDeleteDetailsConfirmation(){
    setClient({ ...client, destinos: auxArrayDes });
    setShowDeleteDetailsConfirmation(false)
    setSendingData(false);
    setShowDeleteConfirmation(false)
    setShowModalAdvise(false)
  }

  function handleSetTextWithoutTab(value){
    let isnum = /[^\t]/.test(value);
    if((value.length === 0) || (isnum && value)){
      handleOnChange("alias", value)
    }
  }

  const formatData = () => (
    client?.destinos.filter(d => d.nombreDestino !== 'Libre a bordo planta')
  )

  return (
    <ScreenWithNavItems user={credentials ? credentials.user : null} >
      <span style={styles.titleText}>Cliente</span>
      <div style={styles.mainContainer}>
        <ModalToElimate
          title="Confirmación para editar cliente"
          textContent="Se detecto que se eliminaron destinos, una vez guardados los cambios no se podran recuperar. ¿ Esta seguro que desea continuar?"
          showModal={showDeleteConfirmation}
          handleOnClickCancel={() => setShowDeleteConfirmation(false)}
          handleOnClickConfirm={() => handleDeleteButtonClick()}
        />
          <ModalToElimate
          title="Confirmación para editar cliente"
          textContent="Se detecto que se modificaron los destinos, una vez guardados los cambios no se podran recuperar los destinos anteriores. ¿ Esta seguro que desea continuar?"
          showModal={showDeleteDetailsConfirmation}
          saveConfirmation={true}
          handleOnClickCancel={() => HandleSetShowDeleteDetailsConfirmation()}
          handleOnClickConfirm={() => handleButtonClick()}
        />
         <CustomDimmer visible={loadingDimmer}/>
        <div style={styles.formContainer}>
          <Form size="mini">
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Alias: </span>
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <div style={{ display: "flex", width: "45%" }}>
                  <Input
                    value={client.alias}
                    onChange={(e, { value }) => handleSetTextWithoutTab(value)}
                    style={{ width: "100%", margin: 0 }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "50%",
                    marginLeft: "5%",
                    justifyContent: "flex-end",
                  }}
                >
                  <span style={styles.subtitleTextEstado}> Estado: </span>
                  <RadioButtonGroup
                    options={status}
                    selectedValue={client.estado}
                    onSelectoption={(value) => handleOnChange("estado", value)}
                    labelKey="nombre"
                  />
                </div>
              </div>
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Razón Social (RFC): </span>
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {renderSocials(client.razonSocial)}
                <Input
                  value={currentSocial}
                  onChange={(e, { value }) => setCurrentSocial(value)}
                  icon={
                    <Icon
                      name="add"
                      onClick={() => {
                        let aux = [...client.razonSocial, currentSocial];
                        handleOnChange("razonSocial", aux);
                        setCurrentSocial("");
                      }}
                      link
                    />
                  }
                  style={{ width: "100%", margin: 0 }}
                />
              </div>
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}>Representante comercial:</span>
              <Input
                value={client.contacto}
                onChange={(e, { value }) => handleOnChange("contacto", value)}
                style={{ width: "100%", margin: 0 }}
              />
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Correo electronico: </span>
              <Input
                value={client.correoElectronico}
                onChange={(e, { value }) =>
                  handleOnChange("correoElectronico", value)
                }
                style={{ width: "100%", margin: 0 }}
              />
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Teléfono: </span>
              <Input
                value={client.telefono}
                onChange={(e, { value }) => handleOnChange("telefono", value)}
                style={{ width: "100%", margin: 0 }}
              />
            </div>

            <div style={styles.formLine2}>
              <span style={styles.subtitleText}> Notas: </span>
              <div style={{ width: "100%" }}>
                <TextArea
                  value={client.notas}
                  onChange={(event) =>
                    handleOnChange("notas", event.target.value)
                  }
                  style={{ width: "100%", margin: 0, height: 45 }}
                />
              </div>
            </div>
          </Form>
          <div style={{ border: "1px solid black", marginBottom: 5 }} />
          <Form size="mini">
            <div style={styles.formLine2}>
              <div
                style={{
                  display: "flex",
                  width: "65%",
                  justifyContent: "flex-start",
                }}
              >
                <span style={styles.subtitleTextDestino}> Destino: </span>

                <Dropdown
                  style={{ height: "5px ", minHeight: "auto !important", whiteSpace: "nowrap"}}
                  placeholder="Seleccione una geocerca"
                  fluid
                  selection
                  search
                  options={geofences}
                  value={clientDestination.geocerca}
                  onChange={(e, data) =>
                    handleOnChangeNewClientDestination("geocerca", data.value)
                  }
                />
              </div>
              <Input
                value={clientDestination.nombreDestino}
                onChange={(e, { value }) =>
                  handleOnChangeNewClientDestination("nombreDestino", value)
                }
                style={{ width: "35%", margin: 0 }}
              />
            </div>
          </Form>
          <div style={styles.buttonGreenContainer}>
            <Alert
              variant={"warning"}
              style={{
                fontSize: 12,
                padding: 5,
                margin: 0,
                color: "black",
                fontWeight: "bold",
              }}
            >
              Destinos configurados: {client.destinos.length}
            </Alert>
            <Button
              color="grey"
              loading={sendingData}
              style={{ margin: 0, fontSize: 12, backgroundColor: resources.colors.secondary }}
              onClick={() => addNewDestinationToClient()}
            >
              {" "}
              Añadir
            </Button>
          </div>
          <div style={styles.tableContainer2}>
            <StyledTable
              headers={[
                { path: "nombreDestino", title: "Destino" },
                { path: "nombreGeocerca", title: "Nombre Geocerca" },
              ]}
              searchBy={["nombreDestino", "nombreGeocerca"]}
              data={ formatData() }
              handleOnDelete={(obj) => onDeleteDestination(obj)}
              enableDelete={true}
              handleOnClickHeader={(e) =>
                handleFilterDataSecondaryTable(client.destinos, e)
              }
            />
          </div>
          <div style={styles.buttonContainer}>
            {client.id ? (
              <Button
                color="grey"
                loading={sendingData}
                style={{ marginRight: 10, fontSize: 12 }}
                onClick={() => cleanForms()}
              >
                Cancelar cambios
              </Button>
            ) : (
              <></>
            )}
            {client.id ? (
              <Button
                color="red"
                loading={sendingData}
                style={{ marginRight: 10, fontSize: 12 }}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Eliminar
              </Button>
            ) : (
              <></>
            )}
            <Button
              primary
              loading={sendingData}
              style={{ margin: 0, fontSize: 12 }}
              onClick={() => checkSaveData()}
            >
              {" "}
              {client.id ? "Guardar cambios" : "Guardar"}
            </Button>
          </div>
        </div>

        <div style={styles.tableContainer}>
          <div
            style={ styles.clientsInformation }
          >
            <Alert
              variant={"warning"}
              style={{
                fontSize: 12,
                padding: 5,
                marginBottom: 0,
                marginRight: 5,
                width: "100%",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Clientes configurados {clients.length}
            </Alert>
            <ExcelButton
              endpoint='/cliente/download_excel'
              fileName='Clientes'
            />
          </div>
          <div style={{ overflow: "auto" }}>{loadTable()}</div>
        </div>
      </div>
    </ScreenWithNavItems>
  );
};

const styles = {
  mainContainer: {
    display: "flex",
    height: "90%",
    width: "100%",
    padding: 10,
    justifyContent: "space-between",
  },
  titleText: {
    fontSize: "3rem",
    fontWeight: "400",
  },
  formContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    width: "50%",
    padding: 10,
    borderRadius: 5,
  },
  formLine: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  formLine2: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  subtitleText: {
    width: "30%",
    fontSize: 12,
    textAlign: "right",
    paddingRight: 10,
  },
  subtitleTextDestino: {
    fontSize: 12,
    textAlign: "left",
    paddingRight: 10,
  },
  subtitleTextEstado: {
    width: "30%",
    fontSize: 12,
    textAlign: "right",
    paddingRight: 5,
  },
  tableContainer: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    height: "90%",
    width: "45%",
  },
  tableContainer2: {
    display: "flex",
    flexDirection: "row",
    height: "45%",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 10,
    paddingBottom: 10
  },
  buttonGreenContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  buttonsStyle: {
    fontSize: 12,
  },
  clientsInformation: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: 'flex-end',
    flexDirection: "row",
    marginBottom: 5
  }
};

export { Client };
