import React, { useEffect, useState } from 'react'
import { Checkbox } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { createInputValueHandler } from '../../../helpers/input'

const TankCheckbox = () => {
  const context = useOrderAssignationContext()

  const [checkTankOne, setCheckTankOne] = useState(false)

  const handleCheck = createInputValueHandler(setCheckTankOne)

  useEffect(() => {
    if (context.placasTanque === '') return
    setCheckTankOne(true)
  }, [context.placasTanque])

  return (
    <div className="form-field">
      <label>Placas TQ1:</label>
      <Checkbox
        label={ context.placasTanque }
        checked={ checkTankOne }
        onChange={ handleCheck }
      />
    </div>
  )
}

export { TankCheckbox }
