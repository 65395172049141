import React from 'react'
import { MapIcon } from '../icons/MapIcon'

const URL = 'https://www.google.com.sa/maps/search/'
const getUrl = (lat, lng) => `${URL}${lat}, ${lng}`

const MapLink = ({ latitude = 0, longitude = 0 }) => {

  const handleShowMap = () => window.open(getUrl(latitude, longitude), '_blank')

  return (
    <span id="mapIconContainer">
    {
      latitude ? 
      (<span onClick={ handleShowMap }><MapIcon/></span>) :
      (<i>Sin datos</i>)
    }
    </span>
  )
}

export { MapLink }
