import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import {
  useOrdersInTransitIncidencesContext
} from '../../../../contexts/OrdersInTransitIncidencesContext'

const AffectedCheck = () => {
  const context = useOrdersInTransitIncidencesContext()

  const changeConfirmation = (_, { checked }) => (
    context.setAfectaServicio(checked)
  )
  
  return (
    <div className='form-field'>
      <label>¿Afecta al servicio?</label>
      <Checkbox
        onChange={ changeConfirmation }
        checked={ context.afectaServicio }
      />
    </div>
  )
}

export { AffectedCheck }
