import React from 'react'
import { Form, TextArea } from 'semantic-ui-react'
import {
  useOrdersInTransitInfoModalContext
} from '../../../../contexts/OrdersInTransitInfoModalContext'

const ShipmentNotesInput = () => {
  const contextModal = useOrdersInTransitInfoModalContext()
  
  const getValue = () => contextModal.boardingRemarks || ''
  const handleInput = e => contextModal.setBoardingRemarks(e.target.value)

  return (
    <div className='notas-pedido'>
      <div>Observaciones del embarque:</div>
      <Form>
        <TextArea
          placeholder="Observaciones Embarque"
          value={ getValue() }
          onChange={ handleInput }
        />
      </Form>
    </div>
  )
}

export { ShipmentNotesInput }
