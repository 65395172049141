import React, { createContext, useContext, useState } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { CustomDimmer } from '../components'
import { dateToYYYYMMDD } from '../helpers/date'
import { showErrorAlert } from '../helpers/notifications'
import service from '../services/travelsLog'

const TravelsLogContext = createContext()

const TravelsLogProvider = ({ children }) => {
  const { credentials } = useSelector(state => state.auth)

  const [isLoading, setIsLoading] = useState(false)
  const [units, setUnits] = useState([])
  const [plantId, setPlantId] = useState('')
  const [shipmentCompanyId, setShipmentCompanyId] = useState('')
  const [[startDate, endDate], setDateRange] = useState([null, null])

  const getParams = () => ({
    plantId,
    shipmentCompanyId: shipmentCompanyId || null,
    startDate: startDate ? dateToYYYYMMDD(startDate) : null,
    endDate: endDate ? dateToYYYYMMDD(endDate) : null
  })

  const search = async  () => {
    if (plantId < 1) return showErrorAlert('Seleccione una planta')
    setIsLoading(true)
    const token = credentials.token
    setUnits(await service.get(token, getParams()))
    setIsLoading(false)
  }

  const context = {
    units,
    setUnits,
    plantId,
    setPlantId,
    shipmentCompanyId,
    setShipmentCompanyId,
    startDate,
    endDate,
    setDateRange,
    search
  }

  return (
    <AuthProvider>
      <TravelsLogContext.Provider value={ context }>
        <CustomDimmer visible={ isLoading } />
        { children }
      </TravelsLogContext.Provider>
    </AuthProvider>
  )
}

const useTravelsLogContext = () => useContext(TravelsLogContext)

export { TravelsLogProvider, useTravelsLogContext }
