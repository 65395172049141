import React from 'react'
import { Form, Icon } from 'semantic-ui-react'
import { useParkedUnitsContext } from '../../contexts/ParkedUnitsContext'
import { FormActions } from './FormActions'
import { InputChecklist } from './form_unit/InputChecklist'
import { InputDateAccess } from './form_unit/InputDateAccess'
import { InputDriver } from './form_unit/InputDriver'
import { InputGeofence } from './form_unit/InputGeofence'
import { InputNotes } from './form_unit/InputNotes'
import { InputPhone } from './form_unit/InputPhone'
import { InputShipmentCompany } from './form_unit/InputShipmentCompany'
import { InputStatus } from './form_unit/InputStatus'
import { InputUnitName } from './form_unit/InputUnitName'

const FormUnit = () => {
  const context = useParkedUnitsContext()
  
  const handleClick = () => context.setHiddenForm(true)

  return (
    context.hiddenForm ?
      <></> :
      <Form>
        <Icon name="close" onClick={ handleClick } />
        <InputDateAccess/>
        <InputGeofence/>
        <InputUnitName/>
        <InputShipmentCompany/>
        <InputDriver/>
        <InputPhone/>
        <InputStatus/>
        <InputNotes/>
        <InputChecklist/>
        <FormActions/>
      </Form>
  )
}

export { FormUnit }
