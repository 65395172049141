import Swal from "sweetalert2"
import { Toast } from "../utils/common"

const showOkAlert = message => Toast.fire({ title: message })
const showErrorAlert = message => (
  Swal.fire({
    icon: 'error',
    showConfirmButton: false,
    text: message,
    showDenyButton: true,
    denyButtonText: 'Ok'
  })
)

const responseHandler = response => {
  if (response.error || response.errorCode) {
    return showErrorAlert(response.error || response.message)
  }
  showOkAlert(response.message)
}

export { showOkAlert, showErrorAlert, responseHandler }
