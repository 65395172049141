import React from 'react'
import { Form } from 'semantic-ui-react'
import { IdInput } from './order_assignation_form/IdInput'
import { ClientInput } from './order_assignation_form/ClientInput'
import { CasoInput } from './order_assignation_form/CasoInput'
import { ProductsButton } from './order_assignation_form/ProductsButton'
import { RequestedDateInput } from './order_assignation_form/RequestedDateInput'
import {
  PlantFinishDropdown
} from './order_assignation_form/PlantFinishDropdown'
import { UnitTypeDropdown } from './order_assignation_form/UnitTypeDropdown'
import { UnitDropdown } from './order_assignation_form/UnitDropdown'
import {
  ShipmentCompanyInput
} from './order_assignation_form/ShipmentCompanyInput'
import { DriverDropdown } from './order_assignation_form/DriverDropdown'
import {
  CommitmentDateInput
} from './order_assignation_form/CommitmentDateInput'
import { ShipmentDateInput } from './order_assignation_form/ShipmentDateInput'
import { HoursInput } from './order_assignation_form/HoursInput'
import { KmInput } from './order_assignation_form/KmInput'
import { TankCheckbox } from './order_assignation_form/TankCheckbox'
import { TankTwoCheckbox } from './order_assignation_form/TankTwoCheckbox'
import { TimezoneInput } from './order_assignation_form/TimezoneInput'
import { NotesInput } from './order_assignation_form/NotesInput'
import { FreightNotesInput } from './order_assignation_form/FreightNotesInput'
import { OrderAssignationFormActions } from './OrderAssignationFormActions'
import {
  RealShipmentDateInput
} from './order_assignation_form/RealShipmentDateInput'

const OrderAssignationForm = () => {
  return (
    <Form size="mini">
      <IdInput/>
      <ClientInput/>
      <CasoInput/>
      <ProductsButton/>
      <RequestedDateInput/>
      <PlantFinishDropdown/>
      <UnitTypeDropdown/>
      <UnitDropdown/>
      <ShipmentCompanyInput/>
      <DriverDropdown/>
      <CommitmentDateInput/>
      <ShipmentDateInput/>
      <RealShipmentDateInput/>
      <HoursInput/>
      <KmInput/>
      <div className="double-column">
        <TankCheckbox/>
        <TimezoneInput/>
      </div>
      <TankTwoCheckbox/>
      <NotesInput/>
      <FreightNotesInput/>
      <OrderAssignationFormActions/>
    </Form>
  )
}

export { OrderAssignationForm }
