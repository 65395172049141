import React, { useEffect, useState } from 'react'
import { Checkbox } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { createInputValueHandler } from '../../../helpers/input'

const TankTwoCheckbox = () => {
  const context = useOrderAssignationContext()

  const [checkTankTwo, setCheckTankTwo] = useState(false)

  useEffect(() => {
    setCheckTankTwo(hasPlate())
    // eslint-disable-next-line
  }, [context.placasTanqueDos])

  const handleCheck = createInputValueHandler(setCheckTankTwo)

  const hasPlate = () => context.placasTanqueDos.trim().length > 0

  return (
    <div className="form-field">
      <label>Placas TQ2:</label>
      <Checkbox
        label={ context.placasTanqueDos }
        disabled={ !hasPlate() }
        checked={ checkTankTwo }
        onChange={ handleCheck }
      />
    </div>
  )
}

export { TankTwoCheckbox }
