import React from 'react'
import { PlantsDropdown } from '../../PlantsDropdown'
import {
  useAssignmentParticipationContext
} from '../../../contexts/AssignmentParticipationContext'

const Plant = () => {
  const context = useAssignmentParticipationContext()

  return (
    <div className='plant-container'>
      <label>Planta: </label>
      <PlantsDropdown
        setSelectedPlant={ context.setPlantId }
      />
    </div>
  )
}

export { Plant }
