import React from 'react'
import {
  useAuthorizedStaysLogContext
} from '../../../contexts/AuthorizedStaysLog'
import { toCurrency } from '../../../helpers/currency'

const StayPriceByDay = () => {
  const context = useAuthorizedStaysLogContext()

  return (
    <div className="form-field">
      <label>Costo por día:</label>
      <label>{ toCurrency(context.info?.costoDiario || 0) }</label>
    </div>
  )
}

export { StayPriceByDay }
