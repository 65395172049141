import React from 'react'
import { AddButton } from './no_availability_evidence_form_actions/AddButton'
import { Invoice } from './no_availability_evidence_form/Invoice'
import { Table } from './Table.js'
import { ImageEvidence } from './no_availability_evidence_form/ImageEvidence'
import { SaveButton } from './no_availability_evidence_form_actions/SaveButton'
import {
  DescriptionEvidence
} from './no_availability_evidence_form/DescriptionEvidence'
import {
  ReactivateUnitButton
} from './no_availability_evidence_form_actions/ReactivateUnitButton'
import {
  DownloadPdfButton
} from './no_availability_evidence_form_actions/DownloadPDFButton'

const UploadEvidenceFormSectionTwo = () => {

  return (
    <div className='form-section-two'>
      <label className='headline'>Evidencia y reporte con acciones</label>
      <Invoice />
      <div className='evidence-container'>
        <div className='image'>
          <ImageEvidence />
        </div>
        <div className='form-field-two'>
          <DescriptionEvidence />  
          <AddButton />
        </div>
      </div>
      <Table />
      <div className='foot-actions'>
        <DownloadPdfButton />
        <ReactivateUnitButton />
        <SaveButton />
      </div>
    </div>
  )
}

export { UploadEvidenceFormSectionTwo }
