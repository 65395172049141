import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CustomDimmer } from '../components'
import { dateToYYYYMMDD } from '../helpers/date'
import { showErrorAlert } from '../helpers/notifications'
import { getById, getIncidencesLog, saveIncidence } from '../services/incidencesLog'
import { AuthProvider } from './authContext'

const IncidencesLogContext = createContext()

const IncidencesLogProvider = ({ children }) => {
  const { credentials } = useSelector(state => state.auth)

  const [isLoading, setIsLoading] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [incidences, setIncidences] = useState([])
  const [plantId, setPlantId] = useState('')
  const [[startDate, endDate], setDateRange] = useState([null, null])
  const [selectedIncidence, setSelectedIncidence] = useState(null)
  
  const [notaCredito, setNotaCredito] = useState('')
  const [montoNotaCredito, setMontoNotaCredito] = useState('')
  const [seguimientoNotaCredito, setSeguimientoNotaCredito] = useState('')
  const [fechaAplicada, setFechaAplicada] = useState('')

  useEffect(() => {
    setNotaCredito(selectedIncidence?.notaCredito)
    setMontoNotaCredito(selectedIncidence?.montoNotaCredito)
    setSeguimientoNotaCredito(selectedIncidence?.seguimientoNotaCredito)
    setFechaAplicada(selectedIncidence?.fechaAplicada)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncidence])

  const search = async  () => {
    if (plantId < 1) return showErrorAlert('Seleccione una planta')
    setIsWorking(true)
    const token = credentials.token
    const from = startDate ? dateToYYYYMMDD(startDate) : null
    const to = endDate ? dateToYYYYMMDD(endDate) : null
    setIncidences(await getIncidencesLog(token, plantId, from, to))
    setIsWorking(false)
  }

  const selectIncidence = async id => {
    setIsLoading(true)
    const response = await getById(credentials.token, id)
    setSelectedIncidence(response)
    setIsLoading(false)
  }

  const getSaveParams = () => ({
    id: selectedIncidence.incidenciaId,
    notaCredito: notaCredito || null,
    montoNotaCredito: montoNotaCredito || null,
    seguimientoNotaCredito: seguimientoNotaCredito || null,
    fechaAplicada: fechaAplicada || null
  })

  const save = async () => {
    setIsLoading(true)
    const response = await saveIncidence(credentials.token, getSaveParams())
    if (response) {
      setSelectedIncidence(null)
      search()
    }
    setIsLoading(false)
  }

  const context = {
    incidences,
    setIncidences,
    plantId,
    setPlantId,
    startDate,
    endDate,
    setDateRange,
    search,
    selectIncidence,
    save,
    selectedIncidence,
    setSelectedIncidence,
    notaCredito,
    setNotaCredito,
    montoNotaCredito,
    setMontoNotaCredito,
    seguimientoNotaCredito,
    setSeguimientoNotaCredito,
    fechaAplicada,
    setFechaAplicada,
    isWorking
  }

  return (
    <AuthProvider>
      <IncidencesLogContext.Provider value={ context }>
        <CustomDimmer visible={ isLoading } />
        { children }
      </IncidencesLogContext.Provider>
    </AuthProvider>
  )
}

const useIncidencesLogContext = () => useContext(IncidencesLogContext)

export { IncidencesLogProvider, useIncidencesLogContext }
