import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dropdown, Form } from "semantic-ui-react"
import { CustomDimmer, ScreenWithNavItems, StyledTable } from '../components'
import { getAuthFromStorage } from '../redux/actions'
import { getGeneralInfo } from '../services/ergonApi'
import { MapLink } from '../components/yard_access_log/MapLink'
import { showErrorAlert } from '../helpers/notifications'
import resources from '../resources'

const headers = [
  { path: 'fechaIngreso',    title: 'Fecha de ingreso' },
  { path: 'fechaSalida',     title: 'Fecha de salida' },
  { path: 'economico',       title: 'Económico' },
  { path: 'placas',          title: 'Placas' },
  { path: 'objectuid',       title: 'Webfleet objectuid' },
  { path: 'planta',          title: 'Planta' },
  { path: 'patio',           title: 'Patio' },
  { path: 'nombre',          title: 'Operador' },
  { path: 'transportista',   title: 'Transportista' },
  { path: 'locationInside',  title: 'Posicion entrada' },
  { path: 'locationOutside', title: 'Posición salida' }
]

export const YardAccessLog = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { credentials } = useSelector((state) => state.auth)
  const [loadingDimmer, setLoadingDimmer] = useState(true)
  const [units, setUnits] = useState([])
  const [logs, setLogs] = useState([])

  useEffect(() => {
    if (credentials == null)
      dispatch(getAuthFromStorage()) 
    else if (!credentials)
      history.push("/")
    else
      load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const getUrl = () => `${resources.urls.ergonApi}/generalUnit`

  const buildHeaders = token => ({
    headers: { Authorization: token }
  })

  const dropdownFormat = unit => (
    { key: unit.id, text: unit.economico, value: unit.id }
  )

  const fetchVehicles = async () => {
    const response = await axios.get(getUrl(), buildHeaders(credentials.token))
    if (response.errorCode) return showErrorAlert(response.message)
    setUnits(response.data.map(dropdownFormat))
  }

  const load = async () => {
    await fetchVehicles()
    setLoadingDimmer(false)
  }

  const toFormatedTable = data => data.map(d => {
    return { 
      ...d,
      locationInside:  (<MapLink latitude={d.latIngreso} longitude={d.lngIngreso} />),
      locationOutside: (<MapLink latitude={d.latSalida}  longitude={d.lngSalida}  />)
    }
  })

  const handleChangeUnit = (_, { value }) => {
    getGeneralInfo(credentials.token, `/accessosPatio/${value}`)
      .then(response => setLogs(toFormatedTable(response)))
      .catch(console.error)
  }
  
  return (
    <ScreenWithNavItems user={credentials ? credentials.user : null}>
      <CustomDimmer visible={loadingDimmer} />
      <div className='screen-yard-access-log'>
        <h1>Historial de accessos a patios</h1>
        <Form>
          <div className="form-field">
            <span>Seleccione una unidad:</span>
            <Dropdown 
              placeholder='Seleccione una unidad'
              fluid
              selection
              search
              options={ units }
              loading={ false } 
              onChange={ handleChangeUnit }
            />
          </div>
        </Form>
        <hr />
        {
          (logs.length ? 
            <StyledTable
              headers={ headers }
              searchBy={ ["economico", "operador"] }
              data={ logs }
            />
            :
            <div className='alert alert-info'>
              <span>No hay datos para mostrar</span>
            </div>
          )
        }
      </div>
    </ScreenWithNavItems>
  )
}
