import React, { createContext, useContext, useEffect, useState } from 'react'
import { CustomDimmer } from '../components'
import {
  downloadPDF,
  maintenanceTypes,
  reactivate,
  save,
  unitData,
  validateSession
} from '../services/noAvailabilityEvidence'
import { responseHandler, showErrorAlert, showOkAlert }
  from '../helpers/notifications'
import { useLocation } from 'react-router-dom'

const UploadEvidenceContext = createContext()

const UploadEvidenceProvider = ({ children }) => {

  const location = useLocation()
  const parts = location.pathname.split('/')
  const getAuth = () => { setToken(parts[parts.length - 1]) }

  const [token,                   setToken]                   = useState('')
  const [code,                    setCode]                    = useState('')
  const [isVerified,              setIsVerified]              = useState(false)
  const [showConfirmReactivate,   setShowConfirmReactivate]   = useState(false)
  const [isLoading,               setIsLoading]               = useState(false)
  const [isWorking,               setIsWorking]               = useState(false)
  const [isLoadingImage,          setIsLoadingImage]          = useState(false)
  const [dateNoAvailability,      setDateNoAvailability]      = useState('')
  const [unit,                    setUnit]                    = useState('')
  const [tankPlates,              setTankPlates]              = useState('')
  const [tankPlatesTwo,           setTankPlatesTwo]           = useState('')
  const [type,                    setType]                    = useState('')
  const [note,                    setNote]                    = useState('')
  const [availabilityDate,        setAvailabilityDate]        = useState('')
  const [maintenanceId,           setMaintenanceId]           = useState('')
  const [maintenanceType,         setMaintenanceType]         = useState([])
  const [areaToIntervene,         setAreaToIntervene]         = useState('')
  const [bugReview,               setBugReview]               = useState('')
  const [noteOfMaintenance,       setNoteOfMaintenance]       = useState('')
  const [invoice,                 setInvoice]                 = useState('')
  const [image,                   setImage]                   = useState('')
  const [descriptionEvidence,     setDescriptionEvidence]     = useState('')
  const [evidencesData,           setEvidencesData]           = useState([])
  const [currentEvidenceId,       setCurrentEvidenceId]       = useState(0)

  const authParams = { token: token, codigo: code }

  useEffect( () => {
    getAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code] )

  const verifySession = async () => {
    setIsWorking(true)
    const response = await validateSession(authParams)
    if (response.error) {
      return responseHandler(response)
    }
    if (response.valid) {
      setIsVerified(response.valid)
      load()
      setIsWorking(false)
      return showOkAlert('Verificado correctamente')
    } else {
      setIsWorking(false)
      return showErrorAlert('Código incorrecto')
    }
  }

  const load = async () => {
    if (token) {
      setIsLoading(true)
      loadUnitData()
      setMaintenanceType(await maintenanceTypes(authParams))
      setIsLoading(false)
    }
  }

  const loadUnitData = async () => {
    const response = await unitData(authParams)
    if (response.error) { return responseHandler(response) }
    setDateNoAvailability(response.fechaNoDisponible || '')
    setUnit(response.economico                       || '')
    setTankPlates(response.placasTanque              || '')
    setTankPlatesTwo(response.placasTanqueDos        || '')
    setType(response.motivoNoDisponible              || '')
    setNote(response.notasNoDisponible               || '')
    setAvailabilityDate(response.fechaEstimada       || '')
    setMaintenanceId(response.tipoMantenimientoId    || '')
    setAreaToIntervene(response.areaIntervenir       || '')
    setBugReview(response.observacionesFalla         || '')
    setNoteOfMaintenance(response.notasMantenimiento || '')
    setInvoice(response.id                           || '')
    setEvidencesData(response.evidencias             || '')
  }

  const clearEvidence = () => {
    setImage('')
    setDescriptionEvidence('')
    setCurrentEvidenceId(0)
  }

  const saveParams = {
    token:                token,
    codigo:               code,
    fechaEstimada:        availabilityDate,
    tipoMantenimientoId:  maintenanceId,
    areaIntervenir:       areaToIntervene,
    observacionesFalla:   bugReview,
    notasMantenimiento:   noteOfMaintenance,
    evidencias:           evidencesData
  }

  const evidenceNotEditing = () => (
    evidencesData.filter((e) => e.id !== currentEvidenceId)
  )

  const add = () => {
    if (!image)
      return showErrorAlert('Favor de ingresar una imagen')
    if (!descriptionEvidence)
      return showErrorAlert('Favor de ingresar una descripción de evidencia')
    setIsWorking(true)
    const newEvidence = {
      id: currentEvidenceId,
      imagen: image,
      evidencia: descriptionEvidence
    }
    if (currentEvidenceId) {
      const evidencesFiltered = evidenceNotEditing(currentEvidenceId)
      setEvidencesData([ ...evidencesFiltered, newEvidence ])
    } else {
      setEvidencesData([ ...evidencesData, newEvidence ])
    }
    clearEvidence()
    setIsWorking(false)
  }

  const onSelectEvidence = (e) => {
    setCurrentEvidenceId(e.id)
    setImage('')
    setDescriptionEvidence(e.evidencia)
  }

  const saveEvidence = async () => {
    if (!maintenanceId)
      return showErrorAlert('Favor de ingresar una tipo de mantenimiento')
    if (!evidencesData.length)
      return showErrorAlert('Favor de ingresar una evidencia')
    setIsWorking(true)
    const response = await save(saveParams)
    if (response.error) {
      setIsWorking(false)
      return responseHandler(response)
    }
    load()
    setIsWorking(false)
    return responseHandler(response)
  }

  const reactivateUnit = async () => {
    setIsWorking(true)
    const response = await reactivate(authParams)
    if (response.error) {
      setIsWorking(false)
      return responseHandler(response)
    }
    load()
    setIsWorking(false)
    return responseHandler(response)
  }

  const download = async () => {
    setIsWorking(true)
    await downloadPDF(authParams, invoice, unit)
    setIsWorking(false)
  }

  const context = {
    isVerified,
    dateNoAvailability,
    unit,
    tankPlates,
    tankPlatesTwo,
    type,
    note,
    availabilityDate,
    maintenanceType,
    maintenanceId,
    areaToIntervene,
    bugReview,
    noteOfMaintenance,
    invoice,
    image,
    descriptionEvidence,
    evidencesData,
    isWorking,
    isLoadingImage,
    showConfirmReactivate,

    setCode,
    verifySession,
    setAvailabilityDate,
    setMaintenanceId,
    setAreaToIntervene,
    setBugReview,
    setNoteOfMaintenance,
    setImage,
    setDescriptionEvidence,
    setIsWorking,
    setIsLoadingImage,
    add,
    saveEvidence,
    setShowConfirmReactivate,
    reactivateUnit,
    onSelectEvidence,
    download
  }

  return (
    <UploadEvidenceContext.Provider value={ context }>
      <CustomDimmer visible={ isLoading } />
      { children }
    </UploadEvidenceContext.Provider>
  )
}

const useUploadEvidenceContext = () => useContext(UploadEvidenceContext)

export { UploadEvidenceProvider, useUploadEvidenceContext }
