import React, { createContext, useContext, useState } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { showErrorAlert } from '../helpers/notifications'
import { CustomDimmer } from '../components'
import { getInactivityDetailData } from '../services/inactivityDetailReport'
import { dateToYYYYMMDD } from '../helpers/date'

const InactivityDetailReportContext = createContext()

const InactivityDetailReportProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const [isLoading,            setIsLoading            ] = useState(false)
  const [carrierId,            setCarrierId            ] = useState(null)
  const [[startDate, endDate], setDateRange            ] = useState([null, null])
  const [inactivityDetailData, setInactivityDetailData ] = useState([])
  const excelEndpoint =
    `/detailedInactivityReport/downloadExcel?shipmentCompanyId=${carrierId}
    &beginDate=${(dateToYYYYMMDD(startDate))}
    &endDate=${(dateToYYYYMMDD(endDate))}`.trim().replace(/\s+/g,"")

  const prepareParams = () => (
    {
      shipmentCompanyId: carrierId,
      beginDate: dateToYYYYMMDD(startDate),
      endDate: dateToYYYYMMDD(endDate)
    }
  )

  const hasError = response => response.errorCode || response.status

  const handleResponse = data => {
    if (hasError(data))
      return showErrorAlert('Hubo un error, favor de intentar de nuevo')
    setInactivityDetailData(data)
  }

  const search = async () => {
    if (!startDate || !endDate)
      return showErrorAlert('Debes seleccionar un rango de fechas')
    setIsLoading(true)
    getInactivityDetailData(prepareParams(), credentials.token)
      .then(handleResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsLoading(false))
  }

  const context = {
    isLoading,
    carrierId,
    startDate,
    endDate,
    inactivityDetailData,
    excelEndpoint,
    setCarrierId,
    setDateRange,
    search,
    setInactivityDetailData
  }

  return (
    <AuthProvider>
      <InactivityDetailReportContext.Provider value={ context }>
      <CustomDimmer visible={ isLoading }/>
        { children }
      </InactivityDetailReportContext.Provider>
    </AuthProvider>
  )
}

const useInactivityDetailReportContext = () => (
  useContext(InactivityDetailReportContext)
)

export { InactivityDetailReportProvider, useInactivityDetailReportContext }
