import React, { createContext, useContext, useState } from 'react'
import { CustomDimmer } from '../components'
import { useSelector } from 'react-redux'
import { AuthProvider } from './authContext'
import { showErrorAlert } from '../helpers/notifications'
import { dateToYYYYMMDD } from '../helpers/date'
import { getAssignmentData } from '../services/assignmentParticipation'

const AssignmentParticipationContext = createContext()

const AssignmentParticipationProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const [isLoading,            setIsLoading      ] = useState(false)
  const [plantId,              setPlantId        ] = useState(null)
  const [[startDate, endDate], setDateRange      ] = useState([null, null])
  const [assignations,         setAssignations   ] = useState(0)
  const [units,                setUnits          ] = useState(0)
  const [kpi,                  setKPI            ] = useState(0)
  const [assignmentData,       setAssignmentData ] = useState([])
  const excelEndpoint =
    `/assignmentParticipationReport/downloadExcel?plantId=${plantId}
    &beginDate=${(dateToYYYYMMDD(startDate))}
    &endDate=${(dateToYYYYMMDD(endDate))}`.trim().replace(/\s+/g,"")

  const prepareUrlParams = () => (
    {
      plantId: plantId,
      beginDate: dateToYYYYMMDD(startDate),
      endDate: dateToYYYYMMDD(endDate)
    }
  )

  const hasError = response => response.errorCode || response.status

  const handleResponse = data => {
    if (hasError(data))
      return showErrorAlert('Hubo un error, favor de intentar de nuevo')
    setAssignations(data.asignaciones)
    setUnits(data.unidades)
    setKPI(data.kpi)
    setAssignmentData(data.participaciones)
  }

  const search = () => {
    if (!plantId)
      return showErrorAlert('Debes seleccionar una planta')
    if (!startDate || !endDate)
      return showErrorAlert('Debes seleccionar un rango de fechas')
    setIsLoading(true)
    getAssignmentData(prepareUrlParams(), credentials.token)
      .then(handleResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsLoading(false))
  }

  const context = {
    plantId,
    startDate,
    endDate,
    assignations,
    units,
    kpi,
    assignmentData,
    excelEndpoint,
    setPlantId,
    setDateRange,
    search,
    setAssignmentData
  }

  return (
    <AuthProvider>
      <AssignmentParticipationContext.Provider value={ context }>
        <CustomDimmer visible={ isLoading } />
        { children }
      </AssignmentParticipationContext.Provider>
    </AuthProvider>
  )
}

const useAssignmentParticipationContext = () => (
  useContext(AssignmentParticipationContext)
)

export { AssignmentParticipationProvider, useAssignmentParticipationContext }
