import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Swal1, Toast } from '../utils/common'
import { Form } from '../components/order_confirmation/Form'
import { Table } from '../components/order_confirmation/Table'
import { Button } from 'semantic-ui-react'
import { confirmOrder, getGeneralInfo } from '../services/ergonApi'

const OrderConfirmation = () => {
  const { token } = useParams()
  const [order, setOrder] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getOrder()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isDisabled = () => order?.estadoPedido !== 4

  const showErrorMessage = ({ message }) => Swal1.fire({ title: message })

  const handleResponse = response => {
    if (response.errorCode) return showErrorMessage(response)
    setOrder(response)
  }

  const handleError = error => {
    if (error.errorCode) return showErrorMessage(error)
    showErrorMessage({ message: error })
  }

  const getOrder = () => {
    getGeneralInfo(token, '/orderConfirmation')
      .then(handleResponse)
      .catch(handleError)
      .finally(() => setIsLoading(false))
  }

  const getCurrentTimezone = () => (
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const buildParams = () => ({
    token: token,
    folio: order.folio,
    timezone: getCurrentTimezone()
  })

  const handleSucess = response => {
    if (response.errorCode) return showErrorMessage(response)
    Toast.fire({ title: response.message })
  }

  const confirm = () => {
    setIsLoading(true)
    confirmOrder(token, buildParams())
      .then(handleSucess)
      .catch(handleError)
      .finally(getOrder)
  }

  return (
    <div className='screen-order-confirmation'>
      <Form  order={ order } />
      <Table products={ order?.detalles } />
      <div className='btn-container'>
        <Button
          color='primary'
          disabled={ isDisabled() }
          loading={ isLoading }
          onClick={ confirm }
        >
          Confirmar
        </Button>
        </div>
    </div>
  )
}

export { OrderConfirmation }
