import React from 'react'
import resources from '../../resources'
import { Totals } from './Totals'

const Header = ({ currentPage = 0, pages = 0, orders = {} }) => {
  return (
    <div className="header-orders-todays-container" > 
      <div className="header-orders-todays-content"> 
        <Totals orders={ orders } />
      </div>
      <div className="header-orders-todays-content">
        <span className="pages-count">
          Pag { currentPage + 1 } de { pages }
        </span>
      </div>
      <div className="header-orders-todays-content">
        <img 
          src={resources.images.ergonLogo} 
          alt="Ergon logo"
        />
      </div>
    </div>
  )
}

export { Header }
