import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { CustomDimmer } from '../components'
import { deletteAnObject, getGeneralInfo, saveAnObject } from '../services/ergonApi'
import { responseHandler, showErrorAlert } from '../helpers/notifications'

const IncidencesContext = createContext()

const IncidencesProvider = ({ children }) => {
  const { credentials } = useSelector(state => state.auth)

  useEffect(() => {
    if (credentials) load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const [isLoading, setIsLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [isWorking, setIsWorking] = useState(false)

  const [showConfirmDestroy, setShowConfirmDestroy] = useState(false)

  const [description,         setDescription]         = useState('')
  const [applicableTo,        setApplicableTo]        = useState(1)
  const [status,              setStatus]              = useState(1)
  const [incidenceTypes,      setIncidenceTypes]      = useState([])
  const [typeId,              setTypeId]              = useState()
  const url = '/incidenceType/'

  const load = async () => {
    setIncidenceTypes(await getIncidenceTypes())
    setIsLoading(false)
  }

  const getIncidenceTypes = async () => {
    return await getGeneralInfo(credentials.token, url)
  }

  const clean = () => {
    setDescription('')
    setApplicableTo(1)
    setStatus(1)
    setIncidenceTypes([])
    setTypeId()
    load()
    setIsEditing(false)
  }

  const onSelectType = incidenceType => {
    setIsWorking(true)
    getTypeById(incidenceType.id)
    setIsWorking(false)
    setIsEditing(true)
  }

  const getSaveParams = () => ({
    descripcion: description,
    aplicacionId: applicableTo,
    estado: status
  })

  const save = async () => {
    if (!description)
      return showErrorAlert('Debes escribir una descripción para la incidencia')
    setIsWorking(true)
    const params = getSaveParams()
    const response = await saveAnObject(credentials.token, url, params)
    responseHandler(response)
    if (!response.error) {
      clean()
      load()
    }
    setIsWorking(false)
  }

  const getTypeById = async (id) => {
    setIsWorking(true)
    const response = await getGeneralInfo(credentials.token, url + id)
    if (response.error) {
      setIsWorking(false)
      return responseHandler(response)
    }
    setApplicableTo(response.aplicacionId)
    setStatus(response.estado)
    setTypeId(response.id)
    setDescription(response.descripcion)
    setIsWorking(false)
  }

  const getEditParams = () => ({
    id: typeId,
    descripcion: description,
    aplicacionId: applicableTo,
    estado: status
  })

  const edit = async () => {
    if (!description)
      return showErrorAlert('Debes escribir un nombre para el motivo')
    setIsWorking(true)
    const params = getEditParams()
    const response = await saveAnObject(credentials.token, url, params)
    responseHandler(response)
    if (!response.error) {
      clean()
      load()
    }
    setIsWorking(false)
  }

  const destroy = async () => {
    setIsWorking(true)
    const response = await deletteAnObject(credentials.token, url, typeId)
    responseHandler(response)
    if (!response.error) {
      clean()
      load()
    }
    setIsWorking(false)
  }

  const context = {
    isLoading,
    isEditing,
    isWorking,
    description,
    applicableTo,
    status,
    incidenceTypes,
    showConfirmDestroy,

    setIsLoading,
    setIsEditing,
    setIsWorking,
    clean,
    save,
    onSelectType,
    setDescription,
    setApplicableTo,
    setStatus,
    setIncidenceTypes,
    edit,
    destroy,
    setShowConfirmDestroy
  }

  return (
    <AuthProvider>
      <IncidencesContext.Provider value={ context }>
      <CustomDimmer visible={ isLoading } />
        { children }
      </IncidencesContext.Provider>
    </AuthProvider>
  )
}

const useIncidencesContext = () => useContext(IncidencesContext)

export { IncidencesProvider, useIncidencesContext }
