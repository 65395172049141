import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { createInputValueHandler } from '../../../../helpers/input'
import { Input } from 'semantic-ui-react'

const Brand = () => {
  const context = useTanksContext()

  const InputHandler = createInputValueHandler(context.setBrand)

  return (
    <div className='brand-container'>
      <label className='title'>Marca:</label>
      <Input
        type='text'
        value={ context.brand || ''}
        onChange={ InputHandler }
        className='value'
      />
    </div>
  )
}

export { Brand }
