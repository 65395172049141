import React from 'react'
import { Button } from 'semantic-ui-react'
import { useIncidencesContext } from '../../../../contexts/IncidencesContext'

const CleanButton = () => {
  const context = useIncidencesContext()

  return (
    <>
    {
      context.isEditing ?
      (
        <Button
          color='grey'
          loading={ context.isWorking }
          onClick={ context.clean }
          >
            Cancelar cambios
        </Button>
      ) : (<></>)
    }
    </>
  ) 
}

export { CleanButton }