import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthProvider } from './authContext'
import { useSelector } from 'react-redux'
import { getGeneralInfo, getImageFromServer } from '../services/ergonApi'
import { showErrorAlert, showOkAlert } from '../helpers/notifications'
import { CustomDimmer, ModalToElimate } from '../components'
import { create, destroyUser, update } from '../services/users'

const UsersContext = createContext()

const UsersContextProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const [email, setEmail] = useState('')
  const [image, setImage] = useState('')
  const [imageUid, setImageUid] = useState('')
  const [isCreating, setIsCreating] = useState(true)
  const [isDeleting, setIsDeleting] = useState(false)
  const [id, setId] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [level, setLevel] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [permissions, setPermissions] = useState([])
  const [permissionsOptions, setPermissionsOptions] = useState([])
  const [phone, setPhone] = useState('')
  const [plantIds, setPlantIds] = useState('')
  const [profile, setProfile] = useState('')
  const [notes, setNotes] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    if (credentials) {
      getPermissions()
      getUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const handleError = ({ message }) => showErrorAlert(message)

  const hasError = response => response.errorCode || response.status

  const isPlantLevel  = () => level === 2
  const isUserProfile = () => profile === 2

  const getUsers =  async () => {
    getGeneralInfo(credentials.token, '/users')
      .then(response => setUsers(response))
      .catch(handleError)
      .finally(() => setIsLoading(false))
  }

  const getPermissions =  async () => {
    getGeneralInfo(credentials.token, '/users/permissions')
      .then(response => setPermissionsOptions(response))
      .catch(handleError)
  }

  const getUser = async user => {
    getGeneralInfo(credentials.token, `/users/${user.id}`)
      .then(response => onGetUser(response))
      .catch(handleError)
  }

  const convertImage = async ({ imagenUid }) => {
    setIsLoadingImage(true)
    const img = await getImageFromServer(credentials.token, imagenUid)
    setImage(`data:image/*;base64, ${img}`)
    setIsLoadingImage(false)
  }

  const onGetUser = async response => {
    setIsCreating(false)
    setId(response.id)
    setEmail(response.correoElectronico)
    setImageUid(response.imagenUid)
    setLevel(response.nivelId)
    setName(response.nombre)
    setPermissions(response.permisos)
    setPhone(response.celular)
    setPlantIds(response.plantaIds)
    setProfile(response.perfilId)
    setNotes(response.notas)
    if (response.imagenUid) await convertImage(response)
  }

  const onSelectUser = selected => getUser(selected)

  const clean = () => {
    setIsCreating(true)
    setId('')
    setEmail('')
    setImage('')
    setImageUid('')
    setLevel('')
    setName('')
    setPassword('')
    setPermissions('')
    setPhone('')
    setPlantIds('')
    setProfile('')
    setNotes('')
  }

  const atEndSuccess = response => {
    setIsWorking(false)
    clean()
    getUsers()
    showOkAlert(response.message)
  }

  const handleShowErrorAlert = message => {
    showErrorAlert(message)
    setIsWorking(false)
  }

  const handleResponse = ({ data }) => {
    if (hasError(data)) return handleShowErrorAlert(data.message)
    atEndSuccess(data)
  }

  const buildParams = () => ({
    correoElectronico: email,
    contrasena: password,
    imagen: image,
    imagenUid: imageUid,
    nivel: level,
    nombre: name,
    permisos: permissions,
    celular: phone,
    plantas: plantIds,
    perfil: profile,
    notas: notes
  })

  const save = async () => {
    setIsWorking(true)
    if (isCreating)
      return handleResponse(await create(credentials.token, buildParams()))
    handleResponse(await update(credentials.token, buildParams(), id))
  }

  const destroy = async () => {
    setIsDeleting(true)
    handleResponse(await destroyUser(credentials.token, id))
    setShowModal(false)
    setIsDeleting(false)
  }

  const context = {
    email,
    image,
    isCreating,
    isLoadingImage,
    isWorking,
    level,
    name,
    password,
    permissions,
    permissionsOptions,
    phone,
    plantIds,
    profile,
    notes,
    users,

    setEmail,
    setImage,
    setLevel,
    setName,
    setPassword,
    setPermissions,
    setPhone,
    setPlantIds,
    setProfile,
    setShowModal,
    setNotes,
    setUsers,

    clean,
    isPlantLevel,
    isUserProfile,
    onSelectUser,
    save
  }

  return (
    <AuthProvider>
      <UsersContext.Provider value={ context } >
        <ModalToElimate
          title='Confirmación para eliminar usuario'
          textContent='Una vez eliminado no se podrá recuperar. ¿Está seguro que desea eliminar?'
          showModal={ showModal }
          deleting={ isDeleting }
          handleOnClickCancel={ () => setShowModal(false) }
          handleOnClickConfirm={ destroy }
        />
        <CustomDimmer visible={isLoading} />
        { children }
      </UsersContext.Provider>
    </AuthProvider>
  )
}

const useUsersContext = () => useContext(UsersContext)

export { UsersContextProvider, useUsersContext }
