import React from 'react'
import { Input } from 'semantic-ui-react'
import { useUploadEvidenceContext } 
  from '../../../contexts/UploadEvidenceContext'
import { createInputValueHandler } from '../../../helpers/input'

const CodeInput = () => {
  const context = useUploadEvidenceContext()

  const handleLogin = createInputValueHandler(context.setCode)

  return (
    <div className='code-verification'>
      <div className='title'>
       <label>
        Favor de ingresar el código proporcionado mediante el correo electrónico
       </label>
      </div>
      <Input
        type='text'
        maxLength='8'
        onChange={ handleLogin }
        value={ context.code } />
    </div>
  )
}

export { CodeInput }
