import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const FreightNotesInput = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => context.orderDetails.info.notaFlete

  return (
    <div className='notas-pedido'>
      <div>Notas Flete:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { FreightNotesInput }
