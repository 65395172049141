import React from 'react'
import { StyledTable } from '../StyledTable'

const headers = [
  { path: 'folio',           title: 'Folio'                     },
  { path: 'fechaPedido',     title: 'Fecha de registro'         },
  { path: 'fechaCarga',      title: 'Fecha planeada de carga'   },
  { path: 'fechaCompromiso', title: 'Fecha planeada de entrega' },
  { path: 'estado',          title: 'Estado'                    },
  { path: 'action',          title: ''                          },
  { path: 'unassign',        title: ''                          }
]
const searchBy = ['folio', 'estado']

const Table = ({ orders = [], hasFinishableOrders = false }) => {
  return (
    orders.length ? 
      <StyledTable
        headers={ headers }
        searchBy={ searchBy }
        data={ orders }
      /> :
      (
        hasFinishableOrders ?
          <div className='alert alert-info'>
            <span>Esta unidad no tiene pedidos sin terminar</span>
          </div> : <></>
      )
  )
}

export { Table }
