import React from 'react'
import { Radio } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'
import { useMotivesContext } from '../../../contexts/MotivesContext'

const RequireEvidence = () => {
  const context = useMotivesContext()
  const requireEvidence = createInputValueHandler(context.setRequireEvidence)

  return (
    <div className="form-field">
      <label>Requiere evidencia:</label>
      <div className='radio-group'>
        <Radio
          label="Si"
          value={ 1 }
          checked={ context.requireEvidence === 1 }
          onChange={ requireEvidence }
        />
        <Radio
          label="No"
          value={ 0 }
          checked={ context.requireEvidence === 0 }
          onChange={ requireEvidence }
        />
      </div>
    </div>
  )
}  

export { RequireEvidence }
