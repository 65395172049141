import React, { useState } from 'react'
import { useParkedUnitsContext } from '../../contexts/ParkedUnitsContext'

const LinkDownloadChecklist = ({ id }) => {
  const context = useParkedUnitsContext()

  const [isDownloading, setIsDownloading] = useState(false)

  const handleClick = async () => {
    setIsDownloading(true)
    await context.downloadChecklist(id)
    setIsDownloading(false)
  }

  return (
    isDownloading ? 
      'Descargando...' :
      <strong className="link" onClick={ handleClick } > 
        Descargar
      </strong>
  )
}

export { LinkDownloadChecklist }
