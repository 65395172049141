import React from 'react'
import { TextArea } from 'semantic-ui-react'
import { useOrderAssignationContext } from '../../../contexts/OrderAssignationContext'
import { createInputValueHandler } from '../../../helpers/input'

const FreightNotesInput = () => {
  const context = useOrderAssignationContext()

  const handleNotes = createInputValueHandler(context.setNotaFlete)
  
  return (
    <div className="form-field text-area">
      <label>Notas Flete:</label>
      <TextArea
        placeholder="Notas"
        value={ context.notaFlete }
        onChange={ handleNotes }
      />
    </div>
  )
}

export { FreightNotesInput }
