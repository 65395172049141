import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthProvider } from './authContext'
import { CustomDimmer } from '../components'
import { showErrorAlert, showOkAlert } from '../helpers/notifications'
import { useSelector } from 'react-redux'
import {
  deleteTank,
  getMeasureUnits,
  getTankData,
  listTanks,
  saveTank,
  updateTank
} from '../services/tanks'
import { getImageFromServer } from '../services/ergonApi'

const TanksContext = createContext()

const TanksProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const [isEditing,          setIsEditing         ] = useState(false)
  const [isLoading,          setIsLoading         ] = useState(true)
  const [isLoadingImage,     setIsLoadingImage    ] = useState(false)
  const [isWorking,          setIsWorking         ] = useState(false)
  const [canEditVIN,         setCanEditVIN        ] = useState(true)
  const [showConfirmDestroy, setShowConfirmDestroy] = useState(false)
  const [tankPhoto,          setTankPhoto         ] = useState('')
  const [tankStatus,         setTankStatus        ] = useState('')
  const [VIN,                setVIN               ] = useState(null)
  const [tankPlates,         setTankPlates        ] = useState(null)
  const [carrierId,          setCarrierId         ] = useState(0)
  const [brand,              setBrand             ] = useState('')
  const [model,              setModel             ] = useState('')
  const [capacity,           setCapacity          ] = useState('')
  const [measureUnitId,      setMeasureUnitId     ] = useState(null)
  const [measureUnitData,    setMeasureUnitData   ] = useState([])
  const [notes,              setNotes             ] = useState('')
  const [tanksData,          setTanksData         ] = useState([])
  const [tankIdToEdit,       setTankIdToEdit      ] = useState([])
  const excelEndpoint = `/tanks/downloadExcel`

  useEffect(() => {
    if (credentials) load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const measureUnit = async () => (
    setMeasureUnitData(await getMeasureUnits(credentials.token))
  )

  const loadTanks = async () => setTanksData(await listTanks(credentials.token))

  const load = async () => {
    measureUnit()
    loadTanks()
    setIsLoading(false)
  }

  const clean = () => {
    setTankPhoto('')
    setTankStatus('Disponible')
    setVIN(null)
    setTankPlates('')
    setCarrierId(0)
    setBrand('')
    setModel('')
    setCapacity('')
    setMeasureUnitId(null)
    setNotes('')
    setTanksData([])
    load()
    setCanEditVIN(true)
    setIsEditing(false)
    setIsWorking(false)
  }

  const hasError = response => response.errorCode || response.status

  const handleSaveResponse = data => {
    if (hasError(data)) return showErrorAlert(data.message)
    clean()
    showOkAlert(data.message)
  }

  const prepareSaveParams = () => (
    {
      niv:             VIN,
      placas:          tankPlates,
      transportistaId: carrierId,
      marca:           brand,
      modelo:          model,
      capacidad:       capacity,
      unidadMedidaId:  measureUnitId,
      notas:           notes,
      imagen:          tankPhoto
    }
  )

  const save = () => {
    if (!VIN) return showErrorAlert('Favor de ingresar un número NIV.')
    if (!tankPlates) return showErrorAlert('Favor de ingresar placas de tanque.')
    if (!carrierId) return showErrorAlert('Favor de ingresar un transportista.')
    if (!capacity) return showErrorAlert('Favor de ingresar una capacidad.')
    if (!measureUnitId)
      return showErrorAlert('Favor de ingresar unidad de medida.')
    setIsWorking(true)
    saveTank(prepareSaveParams(), credentials.token)
      .then(handleSaveResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsWorking(false))
  }

  const update = async () => {
    if (!VIN) return showErrorAlert('Favor de ingresar un número NIV')
    if (!tankPlates) return showErrorAlert('Favor de ingresar placas de tanque')
    if (!carrierId) return showErrorAlert('Favor de ingresar un transportista')
    if (!capacity) return showErrorAlert('Favor de ingresar una capacidad')
    if (!measureUnitId)
      return showErrorAlert('Favor de ingresar unidad de medida')
    setIsWorking(true)
    updateTank(tankIdToEdit, prepareSaveParams(), credentials.token)
      .then(handleSaveResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsWorking(false))
  }

  const convertImage = async ({ imagenUid }) => {
    const img = await getImageFromServer(credentials.token, imagenUid)
    setTankPhoto(`data:image/*;base64, ${img}`)
  }

  const setTankValues = async tank => {
    setTankPhoto('')
    setTankStatus(tank.estado            || '')
    setVIN(tank.niv                      || '')
    setTankPlates(tank.placas            || '')
    setCarrierId(tank.transportistaId    || '')
    setBrand(tank.marca                  || '')
    setModel(tank.modelo                 || '')
    setCapacity(tank.capacidad           || '')
    setMeasureUnitId(tank.unidadMedidaId || '')
    setNotes(tank.notas                  || '')
    if (tank.imagenUid) await convertImage(tank)
  }

  const handleResponse = async tank => {
    if (hasError(tank)) return showErrorAlert(tank.message)
    setTankValues(tank)
  }

  const onSelectRow = tankData => {
    setIsEditing(true)
    setCanEditVIN(true)
    setIsWorking(true)
    setIsLoadingImage(true)
    setTankIdToEdit(tankData.id)
    getTankData(tankData.id, credentials.token)
      .then(handleResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => {
        setIsWorking(false)
        setIsLoadingImage(false)
      })
    if (credentials.user.perfil.nombre !== 'superAdmin')
      return setCanEditVIN(false)
  }

  const destroy = async () => {
    setIsWorking(true)
    deleteTank(tankIdToEdit, credentials.token)
      .then(handleSaveResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => clean())
  }

  const context = {
    isEditing,
    isLoading,
    isLoadingImage,
    isWorking,
    canEditVIN,
    tankPhoto,
    tankStatus,
    VIN,
    tankPlates,
    carrierId,
    brand,
    model,
    capacity,
    measureUnitId,
    measureUnitData,
    notes,
    tanksData,
    excelEndpoint,
    showConfirmDestroy,
    setShowConfirmDestroy,
    setTankPhoto,
    setVIN,
    setTankPlates,
    setCarrierId,
    setBrand,
    setModel,
    setCapacity,
    setMeasureUnitId,
    setNotes,
    setTanksData,
    clean,
    save,
    onSelectRow,
    update,
    destroy
  }

  return (
    <AuthProvider>
      <TanksContext.Provider value={ context }>
        <CustomDimmer visible={ isLoading } />
          { children }
      </TanksContext.Provider>
    </AuthProvider>
  )
}

const useTanksContext = () => useContext(TanksContext)

export { TanksProvider, useTanksContext }
