import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CustomDimmer, ScreenWithNavItems } from '../components'
import { SearchBar } from '../components/parkimovil_error_log/SearchBar'
import { Table } from '../components/parkimovil_error_log/Table'
import { getAuthFromStorage } from '../redux/actions'
import { getGeneralInfo } from '../services/ergonApi'
import { Swal1 } from '../utils/common'

const toDateFormat = date => date?.toJSON()?.substring(0, 10)

const ParkimovilErrorLog = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const { credentials } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [log, setLog] = useState([])

  useEffect(() => {
    if (credentials == null)
      dispatch(getAuthFromStorage())
    else if (!credentials)
      history.push("/")
    else
      search()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const search = async (startDate=null, endDate=null, records=50) => {
    setLoading(true)
    const params = {
      startDate: toDateFormat(startDate), 
      endDate: toDateFormat(endDate), 
      records: records
    }
    const url = '/parkimovil/logErrores'
    const result = await getGeneralInfo(credentials.token, url, params)
    if (result?.errorCode) {
      setLoading(false)
      return Swal1.fire({ title: result?.message })
    }
    setLog(result)
    setLoading(false)
  }

  return (
    <ScreenWithNavItems user={ credentials ? credentials.user : null }>
      <CustomDimmer visible={ loading }/>
      <div className='screen-parkimovil-error-log'>
        <h1>Bitácora de errores de permisos Parkimovil</h1>
        <SearchBar 
          isSearching={ loading }
          search={ search }
        />
        <Table data={ log } />
      </div>
    </ScreenWithNavItems>
  )
}

export { ParkimovilErrorLog }
