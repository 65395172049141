import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CustomDimmer } from '../components'
import { AuthProvider } from './authContext'
import service from '../services/parkedUnits'
import { handleChecklistDownload } from '../utils/common'
import { responseHandler } from '../helpers/notifications'

const ParkedUnitsContext = createContext()

const ParkedUnitsProvider = ({ children }) => {
  const { credentials } = useSelector(state => state.auth)

  const [isLoading, setIsLoading] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [selectedPlant, setSelectedPlant] = useState('')
  const [statuses, setStatuses] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('')
  const [units, setUnits] = useState([])
  const [currentUnit, setCurrentUnit] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [checklists, setChecklists] = useState([])
  const [hiddenForm, setHiddenForm] = useState(false)

  const [unitInfo, setUnitInfo] = useState({})
  const [notas, setNotas] = useState('')

  useEffect(() => {
    if (credentials) load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  useEffect(() => {
    if (selectedPlant > 0) search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlant, selectedStatus])

  useEffect(() => {
    if (statuses.length) setSelectedStatus(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses])

  useEffect(() => {
    if (showModal) getChecklists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const load = async () => {
    setIsLoading(true)
    setStatuses(await service.getStatuses(credentials.token))
    setIsLoading(false)
  }

  const search = async () => {
    setIsWorking(true)
    const token = credentials.token
    const response = await service.search(token, selectedPlant, selectedStatus)
    setUnits(response)
    clean()
    setIsWorking(false)
  }

  const getChecklists = async () => {
    setIsWorking(true)
    const token = credentials.token
    const response = await service.getChecklists(token, currentUnit.idUnidad)
    setChecklists(response)
    setIsWorking(false)
  }

  const getById = async logId => {
    setIsWorking(true)
    const token = credentials.token
    const response = await service.getById(token, logId, selectedPlant)
    setUnitInfo(response)
    setNotas(response.notas || '')
    setHiddenForm(false)
    setIsWorking(false)
  }

  const save = async () => {
    setIsWorking(true)
    const params = {
      bitacoraId: unitInfo.bitacoraId,
      notas: notas
    }
    const response = await service.save(credentials.token, params)
    responseHandler(response)
    clean()
    setIsWorking(false)
  }

  const sendChecklistTask = async () => {
    setIsWorking(true)
    const unitId = context.unitInfo.unidadId
    const response = await service.sendChecklistTask(credentials.token, unitId)
    responseHandler(response)
    setIsWorking(false)
  }

  const downloadChecklist = async id => {
    setIsWorking(true)
    await handleChecklistDownload(credentials.token, id)
    setIsWorking(false)
  }

  const clean = () => {
    setIsWorking(true)
    setUnitInfo({})
    setNotas('')
    setHiddenForm(false)
    setIsWorking(false)
  }

  const context = {
    isWorking,
    setIsWorking,
    setSelectedPlant,
    statuses,
    selectedStatus,
    setSelectedStatus,
    units,
    setUnits,
    getById,
    currentUnit,
    setCurrentUnit,
    showModal,
    setShowModal,
    checklists,
    downloadChecklist,
    unitInfo,
    notas,
    setNotas,
    clean,
    save,
    sendChecklistTask,
    hiddenForm,
    setHiddenForm
  }

  return (
    <AuthProvider>
      <ParkedUnitsContext.Provider value={ context }>
        <CustomDimmer visible={ isLoading } />
        { children }
      </ParkedUnitsContext.Provider>
    </AuthProvider>
  )
}

const useParkedUnitsContext = () => useContext(ParkedUnitsContext)

export { ParkedUnitsProvider, useParkedUnitsContext }
