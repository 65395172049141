import React from 'react'
import { OrderAssignationForm } from '../components/order_assignation/OrderAssignationForm'
import { ProductsTableModal } from '../components/order_assignation/order_assignation_form/ProductsTableModal'
import { SearchBar } from '../components/order_assignation/SearchBar'
import { Table } from '../components/order_assignation/Table'
import { UnitStateModal } from '../components/order_assignation/UnitStateModal'
import { OrderAssignationProvider } from '../contexts/OrderAssignationContext'
import { UnassignModal } from '../components/order_assignation/UnassignModal'

const OrderAssignation = () => {
  return (
    <OrderAssignationProvider>
      <h1>Asignación de pedidos</h1>
      <div className="screen-order-assignation">
        <div className="search">
          <SearchBar/>
          <Table/>
        </div>
        <OrderAssignationForm/>
      </div>
      <ProductsTableModal/>
      <UnitStateModal/>
      <UnassignModal/>
    </OrderAssignationProvider>
  )
}

export { OrderAssignation }
