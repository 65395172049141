import React, { createContext, useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AuthProvider } from './authContext'
import { CustomDimmer } from '../components'
import { responseHandler, showErrorAlert } from '../helpers/notifications'
import { deletteAnObject, getGeneralInfo, saveAnObject } from '../services/ergonApi'

const MotivesContext = createContext()

const MotivesProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
 
  useEffect(() => {
    if (credentials) load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const [isLoading, setIsLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [isWorking, setIsWorking] = useState(false)

  const [showConfirmDestroy, setShowConfirmDestroy] = useState(false)

  const [description,         setDescription]         = useState('')
  const [requireEvidence,     setRequireEvidence]     = useState(0)
  const [inMaintenance,       setInMaintenance]       = useState(0)
  const [inCleaning,          setInCleaning]          = useState(0)
  const [status,              setStatus]              = useState(1)
  const [noAvailabilityTypes, setNoAvailabilityTypes] = useState([])
  const [typeId,              setTypeId]              = useState('')
  const url = '/noAvailabilityCrud/'

  const load = async () => {
    setNoAvailabilityTypes(await getNoAvailabilityTypes())
    setIsLoading(false)
  }

  const getNoAvailabilityTypes = async () => {
    return await getGeneralInfo(credentials.token, url)    
  }

  const onSelectType = noAvailabilityType => {
    setIsWorking(true)
    getTypeById(noAvailabilityType.id)
    setIsWorking(false)
    setIsEditing(true)
  }

  const getSaveParams = () => ({
    descripcion: description,
    requiereEvidencia: requireEvidence,
    esMantenimiento: inMaintenance,
    esLimpieza: inCleaning,
    estado: status
  })

  const getEditParams = () => ({
    id: typeId,
    descripcion: description,
    requiereEvidencia: requireEvidence,
    esMantenimiento: inMaintenance,
    esLimpieza: inCleaning,
    estado: status
  })

  const save = async () => {
    if (!description)
      return showErrorAlert('Debes escribir una descripción para el motivo')
    setIsWorking(true)
    const params = getSaveParams()
    const response = await saveAnObject(credentials.token, url, params)
    responseHandler(response)
    if (!response.error) {
      clean()
      load()
    }
    setIsWorking(false)
  }

  const getTypeById = async (id) => {
    setIsWorking(true)
    const response = await getGeneralInfo(credentials.token, url + id)
    if (response.error) {
      setIsWorking(false)
      return responseHandler(response)
    }
    setTypeId(response.id)
    setDescription(response.descripcion)
    setRequireEvidence(response.requiereEvidencia)
    setInMaintenance(response.esMantenimiento)
    setInCleaning(response.esLimpieza)
    setStatus(response.estado)
    setIsWorking(false)
  }

  const edit = async () => {
    if (!description)
      return showErrorAlert('Debes escribir un nombre para el motivo')
    setIsWorking(true)
    const params = getEditParams()
    const response = await saveAnObject(credentials.token, url, params)
    responseHandler(response)
    if (!response.error) {
      clean()
      load()
    }
    setIsWorking(false)
  }

  const destroy = async () => {
    setIsWorking(true)
    const response = await deletteAnObject(credentials.token, url, typeId)
    responseHandler(response)
    if (!response.error) {
      clean()
      load()
    }
    setIsWorking(false)
  }

  const clean = () => {
    setDescription('')
    setRequireEvidence(0)
    setInMaintenance(0)
    setInCleaning(0)
    setStatus(1)
    setNoAvailabilityTypes([])
    load()
    setIsEditing(false)
  }

  const context = {
    credentials,
    description,
    requireEvidence,
    inMaintenance,
    inCleaning,
    status,
    noAvailabilityTypes,
    isLoading,
    isEditing,
    isWorking,
    showConfirmDestroy,

    setDescription,
    setRequireEvidence,
    setInMaintenance,
    setInCleaning,
    setStatus,
    setNoAvailabilityTypes,
    setIsLoading,
    setIsEditing,
    setIsWorking,
    setShowConfirmDestroy,
    save,
    edit,
    clean,
    destroy,
    onSelectType
  }

  return (
    <AuthProvider>
      <MotivesContext.Provider value={ context }>
        <CustomDimmer visible={ isLoading } />
        { children }
      </MotivesContext.Provider>
    </AuthProvider>
  )
}

const useMotivesContext = () => useContext(MotivesContext)

export { MotivesProvider, useMotivesContext }
