import React from 'react'
import {
  useUploadEvidenceContext
} from '../../../contexts/UploadEvidenceContext'
import { Form, TextArea } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const DescriptionEvidence = () => {
  const context = useUploadEvidenceContext()
  
  const handleInput = createInputValueHandler(context.setDescriptionEvidence)

  return (
    <div className='notes-container'>
      <label>Evidencia:</label>
      <Form>
        <TextArea
          type='text'
          value={ context.descriptionEvidence }
          onChange={ handleInput }
          placeholder='Ingrese descripción de evidencia'
        />
      </Form>
    </div>
  )
}

export { DescriptionEvidence }
