import React from 'react'
import { useUploadEvidenceContext } 
  from '../../../contexts/UploadEvidenceContext'
import { Form, TextArea } from 'semantic-ui-react'
import { createInputValueHandler } from '../../../helpers/input'

const BugReview = () => {
  const context = useUploadEvidenceContext()

  const handleInput = createInputValueHandler(context.setBugReview)

  return (
    <div className="big-container">
      <label>Reseña de falla:</label>
      <Form>
        <TextArea
          type='text'
          value={ context.bugReview }
          onChange={ handleInput }
          placeholder='Ingrese información de falla'
        />
      </Form>
    </div>
  )
}

export { BugReview }
