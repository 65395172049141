import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getGeneralInfo } from '../services/ergonApi'
import { AuthProvider } from './authContext'
import resources from '../resources/ordersInTransit'
import { CustomDimmer } from '../components'
import { showErrorAlert } from '../helpers/notifications'

const dateTypes = resources.dateTypesDefault

const OrdersInTransitContext = createContext()

const OrdersInTransitProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const [plantName, setPlantName] = useState('')
  const [offset, setOffset] = useState(50)
  const [orders, setOrders] = useState([])
  const [dateType, setDateType] = useState(dateTypes[0].value)
  const [isSearching, setIsSearching] = useState(false)
  const [loadingDimmer, setLoadingDimmer] = useState(false)
  const [selectedPlant, setSelectedPlant] = useState(null)
  const [[startDate, endDate], setDateRange] = useState([null, null])

  const [orderDetails, setOrderDetails] = useState({})
  const [selectedOrder, setSelectedOrder] = useState({})
  const [showOrderInfoModal, setShowOrderInfoModal] = useState(false)

  useEffect(() => {
    if (isAvailableToAutoSearch()) search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlant])

  const isAvailableToAutoSearch = () => (
    selectedPlant && isPlantUser()
  )

  const isPlantUser = () => credentials?.user.nivel.id === 2

  const search = () => {
    if (!selectedPlant) return showErrorAlert('Seleccione una planta')
    setIsSearching(true)
    getGeneralInfo(credentials.token, '/ordersInTransit', buildParams())
      .then(response => setOrders(response))
      .catch(error => showErrorAlert(error?.message))
      .finally(() => {
        setIsSearching(false)
        setLoadingDimmer(false)
      })
  }

  const buildParams = () => ({
    plantId:   selectedPlant,
    limit:     offset,
    startDate: startDate,
    endDate:   endDate,
    dateType:  dateType.key || dateType
  })

  const onClickRow = order => {
    setSelectedOrder(order)
    const url = `/ordersInTransit/details/${order.folio}`
    getGeneralInfo(credentials.token, url)
      .then(response => setOrderDetails(response))
      .catch(error => showErrorAlert(error?.message))
      .finally(() => setShowOrderInfoModal(true))
  }

  const context = {
    credentials,
    orders,
    orderDetails,
    offset,
    startDate,
    endDate,
    selectedPlant,
    dateTypes,
    dateType,
    isSearching,
    plantName,
    loadingDimmer,
    showOrderInfoModal,
    selectedOrder,
    setOffset,
    setDateRange,
    setDateType,
    setSelectedPlant,
    setPlantName,
    setShowOrderInfoModal,
    search,
    onClickRow,
    setOrders
  }

  return (
    <AuthProvider>
      <OrdersInTransitContext.Provider value={context}>
        <CustomDimmer visible={ loadingDimmer } />
        { children }
      </OrdersInTransitContext.Provider>
    </AuthProvider>
  )
}

const useOrdersInTransitContext = () => useContext(OrdersInTransitContext)

export { OrdersInTransitProvider, useOrdersInTransitContext }
