import React from 'react'
import { createInputValueHandler } from '../../../helpers/input'
import { useMotivesContext } from '../../../contexts/MotivesContext'
import { Input } from 'semantic-ui-react'

const DescriptionMotive = () => {
  const context = useMotivesContext()
  const description = createInputValueHandler(context.setDescription)

  return (
    <div className="form-field">
      <label>Descripción:</label>
      <Input
        value={ context.description || '' }
        placeholder={`Nombre de motivo...`}
        onChange={ description }
      />
    </div>
  )
}  

export { DescriptionMotive }
