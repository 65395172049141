import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ScreenWithNavItems,StyledTable ,CustomDimmer } from "../components";
import { Input, Button, Header, Modal, Form, } from "semantic-ui-react";
import { getAuthFromStorage } from "../redux/actions";
import { getCredentials, saveCredentials } from "../services/ergonApi";
import { Toast, Swal1, filterdata } from "../utils/common";
import "../customStyles.css";

const Credentials = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { credentials } = useSelector((state) => state.auth);
  const [userCredentials, setUserCredentials] = useState([]);
  const [userName, setUserName] = useState("");
  const [userValue, setUserValue] = useState("");
  const [userDescription, setUserDescription] = useState("");
  const [userRegistrationDate, setRegistrationDate] = useState("");
  const [userID, setUserID] = useState("");
  const [open, setOpen] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorValue, setErrorValue] = useState(false);
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false);
  const [loadingDimmer, setLoadingDimmer] = useState(true);

  useEffect( () => {
    if (credentials == null) {
      dispatch(getAuthFromStorage());
    } else if (!credentials) {
      history.push("/");
    } else {
      setLoadingDimmer(true)
      fetchCredentials();
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  async function fetchCredentials() {
    let response = await getCredentials(credentials.token);
    if (response.errorCode) {
      Swal1.fire({
        title: response.message,
      });
    } else {
      setUserCredentials(response);
    }
    setLoadingDimmer(false)
  }
  
  function editableTable(data) {
    setErrorDescription(false);
    setErrorValue(false);
    setUserName(data.nombre);
    setUserValue(data.valor);
    setUserDescription(data.descripcion);
    setRegistrationDate(data.fechaRegistro);
    setUserID(data.id);
    setOpen(true);
  }

  async function saveData() {
    if (!userValue.trim() || !userDescription.trim()) {
      if (!userValue.trim()) {
        setErrorValue(true);
      } else {
        setErrorDescription(true);
      }
      return;
    }
    setSendingData(true);
    let obj = {
      id: userID,
      nombre: userName,
      valor: userValue,
      descripcion: userDescription,
      fechaRegistro: userRegistrationDate,
    };

    let response = await saveCredentials(credentials.token, [obj]);
    if (!response.errorCode) {
      Toast.fire({
        title: response.message,
      });
    } else {
      Swal1.fire({
        title: response.message,
      });
    }
    await fetchCredentials();
    setSendingData(false);
    setOpen(false);
    setErrorDescription(false);
    setErrorValue(false);
  }
  function handleChangeValue(value) {
    setUserValue(value);
    setErrorValue(false);
  }

  function handleChangeDescription(value) {
    setUserDescription(value);
    setErrorDescription(false);
  }
  function handleFilterData(data, e) {
    setUserCredentials(filterdata(data, e, reloadDataOfTable));
    setReloadDataOfTable(!reloadDataOfTable);
  }
  function loadTable() {
    return (
      <StyledTable
        headers={[
          { path: "nombre", title: "Nombre" },
          { path: "valor", title: "Valor" },
          { path: "descripcion", title: "Descripción" },
          { path: "fechaRegistro", title: "Fecha Registro" },
        ]}
        searchBy={["nombre", "valor", "descripcion","fechaRegistro"]}
        data={userCredentials}
        handleOnClickRow={(data) => editableTable(data)}
        handleOnClickHeader={(e) => handleFilterData(userCredentials, e)}
      />
    );
  }
  return (
    <ScreenWithNavItems user={credentials ? credentials.user : null}>
      <span style={styles.titleText}>Credenciales</span>
      <div style={styles.mainContent}>
        <div style={styles.tableContainer}>{loadTable()}</div>
      </div>
      <CustomDimmer visible={loadingDimmer}/>

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        style={{
          height: "35%",
          maxHeight: "255px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          fontSize: 12,
        }}
      >
        <Header content="Credenciales" />
        <Modal.Content>
          <Form size="mini">
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> nombre: </span>
              <Input
                disabled
                value={userName}
                style={{ width: "100%", margin: 0 }}
              />
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> valor: </span>
              <Input
                error={errorValue}
                value={userValue}
                onChange={(e, { value }) => handleChangeValue(value)}
                style={{ width: "100%", margin: 0 }}
              />
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> descripcion: </span>
              <Input
                error={errorDescription}
                value={userDescription}
                onChange={(e, { value }) => handleChangeDescription(value)}
                style={{ width: "100%", margin: 0 }}
              />
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> fechaRegistro: </span>
              <Input
                disabled
                value={userRegistrationDate}
                style={{ width: "100%", margin: 0 }}
              />
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={sendingData}
            color="grey"
            onClick={() => setOpen(false)}
            style={{ fontSize: 12 }}
          >
            Cancelar
          </Button>
          <Button
            loading={sendingData}
            color="blue"
            onClick={() => saveData()}
            style={{ fontSize: 12 }}
          >
            Guardar
          </Button>
        </Modal.Actions>
      </Modal>
    </ScreenWithNavItems>
  );
};
const styles = {
  titleText: {
    fontSize: "3rem",
    fontWeight: "400",
  },
  tableContainer: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    height: "90%",
    width: "100%",
    alignItems: "left",
  },
  mainContent: {
    display: "flex",
    height: "90%",
    width: "100%",
    paddingTop: 10,
    justifyContent: "center",
    flexDirection: "row",
  },
  formLine: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  subtitleText: {
    width: "30%",
    fontSize: 12,
    textAlign: "left",
    paddingRight: 20,
    justifyContent: "start",
  },
};
export { Credentials };
