import React from 'react'
import {
  useOrdersInTransitStayContext
} from '../../../../contexts/OrdersInTransitStayContext'

const ClaimedStay = () => {
  const context = useOrdersInTransitStayContext()
  
  return (
    <div className="form-field">
      <label>Estadía reclamada:</label>
      <label>{ context.stayInfo.estadiaReclamada } días</label>
    </div>
  )
}

export { ClaimedStay }
