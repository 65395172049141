import React from 'react'
import { Alert } from 'react-bootstrap'
import { useVehicleContext } from '../../contexts/vehicleContext'

const ConfiguredVehicles = () => {
  const { vehicles, webfleetVehicles } = useVehicleContext()

  const isContextReady = () => vehicles.length && webfleetVehicles.length

  const withGps = () => vehicles.filter(v => !v.sinGps).length

  const calculatePorcentage = () => {
    if (isContextReady())
      return Math.round((withGps() / webfleetVehicles.length) * 100)
  }

  return (
    <Alert variant='warning' >
      Unidades configuradas {withGps()}/<b>{webfleetVehicles.length}</b>
      {" "}({calculatePorcentage() || 0}%).
    </Alert>
  )
}

export { ConfiguredVehicles }
