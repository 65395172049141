import React from 'react'
import { useMotivesContext } from '../../../contexts/MotivesContext'
import { createInputValueHandler } from '../../../helpers/input'
import { Radio } from 'semantic-ui-react'

const InCleaning = () => {
  const context = useMotivesContext()
  const inCleaning = createInputValueHandler(context.setInCleaning)

  return (
    <div className="form-field">
      <label>En limpieza:</label>
      <div className='radio-group'>
        <Radio
          label="Si"
          value={ 1 }
          checked={ context.inCleaning === 1 }
          onChange={ inCleaning }
        />
        <Radio
          label="No"
          value={ 0 }
          checked={ context.inCleaning === 0 }
          onChange={ inCleaning }
        />
      </div>
    </div>
  )
}  

export { InCleaning }
