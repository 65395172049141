const DAYS = [1, 2, 3, 4, 5, 6, 7]

const DAY_NAMES = [
  { dayName: 'Lunes' },
  { dayName: 'Martes' },
  { dayName: 'Miércoles' },
  { dayName: 'Jueves' },
  { dayName: 'Viernes' },
  { dayName: 'Sábado' },
  { dayName: 'Domingo' },
]
const WEEKDAYS = [
  { dayNumber: 1, dayName: 'Lunes'     },
  { dayNumber: 2, dayName: 'Martes'    },
  { dayNumber: 3, dayName: 'Miércoles' },
  { dayNumber: 4, dayName: 'Jueves'    },
  { dayNumber: 5, dayName: 'Viernes'   },
  { dayNumber: 6, dayName: 'Sábado'    },
  { dayNumber: 7, dayName: 'Domingo'   }
]

const buildDaysShifts = schedule => (
  WEEKDAYS.map(d => ({
    dayName: d.dayName,
    day: d.dayNumber,
    morningShift: findDay(schedule, d.dayNumber, 1),
    eveningShift: findDay(schedule, d.dayNumber, 2),
    nightShift: findDay(schedule, d.dayNumber, 3)
  }))
)

const findDay = (data, dayNumber, shiftNumber) => (
  data.find(d => d.dia === dayNumber && d.tipoTurnoId === shiftNumber)
)

const getDefaultSchedule = day => ([
  { tipoTurnoId: 1, dia: day, horaInicio: '00:00', horaFin: '00:00' },
  { tipoTurnoId: 2, dia: day, horaInicio: '00:00', horaFin: '00:00' },
  { tipoTurnoId: 3, dia: day, horaInicio: '00:00', horaFin: '00:00' }
])

const padSchedule = (day, schedules) => (
  getDefaultSchedule(day).map(d => {
    const daySchedule = schedules.find(schedule => (
      schedule.tipoTurnoId === d.tipoTurnoId
    ))
    if (daySchedule) return daySchedule
    return d
  })
)

export {
  DAYS,
  DAY_NAMES,
  WEEKDAYS, 
  buildDaysShifts,
  getDefaultSchedule,
  padSchedule
}
