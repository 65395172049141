import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { createInputValueHandler } from '../../../../helpers/input'
import { Input } from 'semantic-ui-react'

const Capacity = () => {
  const context = useTanksContext()

  const InputHandler = createInputValueHandler(context.setCapacity)

  return (
    <div className='capacity-container'>
      <label className='title'>Capacidad:</label>
      <Input
        type='number'
        value={ context.capacity }
        onChange={ InputHandler }
        className='value'
      />
    </div>
  )
}

export { Capacity }
