import React from 'react'
import {
  useOrdersInTransitContext
} from '../../../../contexts/OrdersInTransitContext'

const TankPlateLabel = () => {
  const context = useOrdersInTransitContext()
  const getLabel = () => context.orderDetails.info.placasTanque || 'Sin asignar'

  return (
    <div>
      <div>Placas TQ1:</div>
      <div>{ getLabel() }</div>
    </div>
  )
}

export { TankPlateLabel }
