import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { ScreenWithNavItems, CustomDimmer } from "../components"
import { getAuthFromStorage } from "../redux/actions"
import {
  getAvailableStatus,
  getDriverEnterprises,
  getImageFromServer,
  getDrivers,
  saveDriver,
  deleteDriver,
  getGeneralInfo,
} from '../services/ergonApi'
import { parseArrayObjectsToDropdownArray, Toast, Swal1 } from '../utils/common'
import { AssignOperatorModal } from '../components/assign_operator_modal/AssignOperatorModal'
import { NavixyConfigMetter } from '../components/drivers/NavixyConfigMetter'
import { ModalDeleteConfirmation } from '../components/drivers/ModalDeleteConfirmation'
import { DriversTable } from '../components/drivers/DriversTable'
import { DriverForm } from '../components/drivers/DriverForm'
import '../customStyles.css'

const getEmptyDriver = () => ({
  id:                   null,
  nombre:               '',
  celular:              '',
  licencia:             '',
  vigenciaLicencia:     '',
  idNavixy:             '',
  navixyTrackerId:      '',
  navixyTrackerLabel:   '',
  navixyTrackerLabelDb: '',
  transportista:        null,
  unidad:               null,
  notas:                '',
  imagenFile:           null,
  imagenUid:            null,
  imagenBase64:         null,
  estado: { id: 1, nombre: 'Activo' },
  aptoMedico:           '',
  sinGps:               false
})

const Driver = () => {

  const history = useHistory()
  const { credentials } = useSelector(state => state.auth)
  const [vehicles, setVehicles] = useState([])
  const [drivers, setDrivers] = useState([])
  const [trackers, setTrackers] = useState([])
  const [navixyDrivers, setNavixyDrivers] = useState([])
  const [status, setStatus] = useState([])
  const [selectedNavixyDriver, setSelectedNavixyDriver] = useState(null)
  const [selectedDriver, setSelectedDriver] = useState({})
  const [driverEnterprises, setDriverEnterprises] = useState([])
  const [sendingData, setSendingData] = useState(false)
  const [driverInfo, setDriverInfo] = useState(getEmptyDriver())
  const [gettingInfo, setGettingInfo] = useState(true)
  const [clearFilterText, setClearFilterText] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [loadingDimmer, setLoadingDimmer] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [lastDriverWithUnit, setLastDriverWithUnit] = useState({})
  const [sinGps, setSinGps] = useState(false)
  const [mixedUnits, setMixedUnits] = useState({})

  const dispatch = useDispatch()

  useEffect(() => {
    if (credentials == null)
      dispatch(getAuthFromStorage())
    else if (!credentials)
      history.push("/")
    else {
      setLoadingDimmer(true)
      fetchDriversEnterprises()
      fetchStatusTypes()
      fetchVehicles()
      fetchDrivers()
      fetchDriversFromNavixy()
      fetchTrackersFromNavixy()
      setGettingInfo(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  useEffect(() => {
    if (selectedDriver.sinGps && !sinGps) {
      cleanNavixyDriver()
      cleanDriverInfo()
    }
    if (sinGps) return setVehicles(mixedUnits.sinGps)
    setVehicles(mixedUnits.conGps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sinGps])

  const cleanNavixyDriver = () => setSelectedNavixyDriver('')
  const cleanDriverInfo   = () => setDriverInfo({
    ...driverInfo,
    idNavixy:             '',
    navixyTrackerId:      '',
    navixyTrackerLabel:   '',
    navixyTrackerLabelDb: ''
  })

  const fetchDriversEnterprises = async () => {
    const response = await getDriverEnterprises(credentials.token)
    if (response.errorCode)
      Swal1.fire({ title: response.message })
    else {
      const driverEnterprises = parseArrayObjectsToDropdownArray(response, "alias")
      setDriverEnterprises(driverEnterprises)
    }
  }

  const fetchDrivers = async () => {
    const response = await getDrivers(credentials.token)
    if (response.errorCode)
      Swal1.fire({ title: response.message })
    else {
      setDrivers(response)
      setClearFilterText(true)
    }
    setLoadingDimmer(false)
  }

  const fetchDriversFromNavixy = async () => {
    const response = await getGeneralInfo(credentials.token, "/operador/navixy/employees")
    if (response.errorCode)
      Swal1.fire({ title: response.message })
    else {
      const navixyDrivers = parseArrayObjectsToDropdownArray(response, "first_name")
      setNavixyDrivers(navixyDrivers)
    }
  }

  const fetchTrackersFromNavixy = async () => {
    const response = await getGeneralInfo(credentials.token, "/operador/navixy/trackers")
    if (response.errorCode)
      Swal1.fire({ title: response.message })
    else {
      const navixyTrackers = parseArrayObjectsToDropdownArray(response, "label")
      setTrackers(navixyTrackers)
    }
  }

  const fetchVehicles = async () => {
    const response = await getGeneralInfo(credentials.token, '/operador/units')
    if (response.errorCode) return Swal1.fire({ title: response.message })
    setMixedUnits(response)
    setVehicles(response.conGps)
  }

  const fetchStatusTypes = async () => {
    const statusTypesResponse = await getAvailableStatus(credentials.token)
    if (statusTypesResponse.errorCode)
      Swal1.fire({ title: statusTypesResponse.message })
    else {
      setStatus(statusTypesResponse)
      setDriverInfo({ ...driverInfo, estado: statusTypesResponse[0] })
    }
  }

  const saveChanges = async () => {
    const response = await saveDriver(credentials.token, driverInfo)
    if (response.errorCode) return Swal1.fire({ title: response.message })
    setDriverInfo(getEmptyDriver())
    setSelectedNavixyDriver(null)
    setClearFilterText(false)
    setSinGps(false)
    await fetchDrivers()
    Toast.fire({ title: response.message })
  }

  const isValidData = () => (
    driverInfo.estado &&
    driverInfo.transportista &&
    driverInfo.unidad &&
    driverInfo.nombre !== "" &&
    driverInfo.licencia !== "" &&
    driverInfo.celular !== "" &&
    driverInfo.vigenciaLicencia &&
    (driverInfo.idNavixy || driverInfo.sinGps)
  )

  const handleButtonClick = async () => {
    setSendingData(true)
    if (isValidData()) {
      if (validateAssignmentUnit()) saveChanges()
    }
    else
      Swal1.fire({ title: 'Faltan datos' })
    setSendingData(false)
  }

  const unitAlreadyTaken = (idUnit, driverId) => (
    drivers.filter(d => {
      if (d.unidad === idUnit && d.id !== driverId) {
        setLastDriverWithUnit(d)
        return true
      }
      return false
    }).length
  )

  const validateAssignmentUnit = () => {
    if(unitAlreadyTaken(driverInfo.unidad, driverInfo.id)) {
      setShowModal(true)
      return false
    }
    return true
  }

  const handleDeleteButtonClick = async () => {
    setSendingData(true)
    const response = await deleteDriver(credentials.token, driverInfo.id)
    setShowDeleteConfirmation(false)
    if (response.errorCode)  Swal1.fire({ title: response.message })
    if (!response.errorCode) Toast.fire({ title: response.message })
    setDriverInfo(getEmptyDriver())
    await fetchDrivers()
    setSendingData(false)
    setSelectedNavixyDriver(null)
  }

  const findNavixyDriverById = navixyId => (
    navixyDrivers.find(obj => obj.value.id === navixyId)?.value || null
  )

  const findNavixyTrackerById = navixyTrackerId => (
    trackers.find(obj => obj.value.id === navixyTrackerId)?.value?.label || null
  )

  const setEditValues = async data => {
    setSelectedDriver(data)
    if (data.imagenUid) {
      const img = await getImageFromServer(credentials.token, data.imagenUid)
      data.imagenBase64 = `data:image/*;base64, ${img}`
    }
    else
      data.imagenFile = null
    if (data.sinGps) {
      setVehicles(mixedUnits.sinGps)
      setDriverInfo(data)
      return setSinGps(data.sinGps)
    }
    const navixyDriver = findNavixyDriverById(data.idNavixy)
    setSelectedNavixyDriver(navixyDriver)
    const navixyTrackerLabel = findNavixyTrackerById(data.navixyTrackerId)
    data.navixyTrackerLabel = navixyTrackerLabel
    setVehicles(mixedUnits.conGps)
    setDriverInfo(data)
    setSinGps(data.sinGps)
  }

  const onSelectNavixyDriver = navixyDriver => {
    const formattedDriverInfo = {
      ...driverInfo,
      nombre:             navixyDriver.first_name || '',
      celular:            navixyDriver.phone || '',
      licencia:           navixyDriver.driver_license_number || '',
      vigenciaLicencia:   navixyDriver.driver_license_valid_till || '',
      idNavixy:           navixyDriver.id || '',
      navixyTrackerId:    navixyDriver.tracker_id || '',
      navixyTrackerLabel: findNavixyTrackerById(navixyDriver.tracker_id)
    }
    setDriverInfo(formattedDriverInfo)
    setSelectedNavixyDriver(navixyDriver)
  }

  const cancelChanges = () => {
    setDriverInfo(getEmptyDriver())
    setSelectedNavixyDriver(null)
    setSinGps(false)
  }

  return (
    <ScreenWithNavItems user={ credentials ? credentials.user : null }>
      <CustomDimmer visible={ loadingDimmer }/>
      <div className="screen-drivers">
        <NavixyConfigMetter
          drivers={ drivers }
          navixyDrivers={ navixyDrivers }
        />
        <span className="title-text">Operadores</span>
        <AssignOperatorModal
          showModal={ showModal }
          setShowModal={ setShowModal }
          saveChanges={ saveChanges }
          lastDriverWithUnit={ lastDriverWithUnit }
        />
        <ModalDeleteConfirmation
          visible={ showDeleteConfirmation }
          onConfirm={ handleDeleteButtonClick }
          onCancel={ () => setShowDeleteConfirmation(false) }
        />
        <div className="body-container">
          <DriverForm
            driverInfo={ driverInfo }
            setDriverInfo={ setDriverInfo }
            setSinGps={ setSinGps }
            sinGps={ sinGps }
            status={ status }
            navixyDrivers={ navixyDrivers }
            loading={ gettingInfo }
            selectedNavixyDriver={ selectedNavixyDriver }
            onSelectNavixyDriver={ onSelectNavixyDriver }
            driverEnterprises={ driverEnterprises }
            vehicles={ vehicles }
            sendingData={ sendingData }
            cancelChanges={ cancelChanges }
            onDelete={ setShowDeleteConfirmation }
            onClick={ handleButtonClick }
          />
          <DriversTable
            drivers={ drivers }
            setDrivers={ setDrivers }
            clearFilterText={ clearFilterText }
            onClickRow={ setEditValues }
            token={ credentials?.token }
          />
        </div>
      </div>
    </ScreenWithNavItems>
  )
}

export { Driver }
