import React, { createContext, useContext, useState } from 'react'
import { CustomDimmer } from '../components'
import { useSelector } from 'react-redux'
import { AuthProvider } from './authContext'
import { showErrorAlert } from '../helpers/notifications'
import { dateToYYYYMMDD } from '../helpers/date'
import { getUnitUseData } from '../services/unitUse'

const UnitUseContext = createContext()

const UnitUseProvider = ({ children }) => {
  const { credentials } = useSelector((state) => state.auth)
  const [isLoading,            setIsLoading  ] = useState(false)
  const [carrierId,            setCarrierId  ] = useState(null)
  const [[startDate, endDate], setDateRange  ] = useState([null, null])
  const [unitUseData,          setUnitUseData] = useState([])
  const excelEndpoint =
    `/unitsUseReport/downloadExcel?shipmentCompanyId=${carrierId}
    &beginDate=${(dateToYYYYMMDD(startDate))}
    &endDate=${(dateToYYYYMMDD(endDate))}`.trim().replace(/\s+/g,"")

  const prepareUrlParams = () => (
    {
      shipmentCompanyId: carrierId,
      beginDate: dateToYYYYMMDD(startDate),
      endDate: dateToYYYYMMDD(endDate)
    }
  )

  const hasError = response => response.errorCode || response.status

  const handleResponse = data => {
    if (hasError(data))
      return showErrorAlert('Hubo un error, favor de intentar de nuevo')
    setUnitUseData(data)
  }

  const search = () => {
    if (!startDate || !endDate)
      return showErrorAlert('Debes seleccionar un rango de fechas')
    setIsLoading(true)
    getUnitUseData(prepareUrlParams(), credentials.token)
      .then(handleResponse)
      .catch(error => showErrorAlert(error.message))
      .finally(() => setIsLoading(false))
  }

  const context = {
    carrierId,
    startDate,
    endDate,
    unitUseData,
    excelEndpoint,
    setCarrierId,
    setDateRange,
    search,
    setUnitUseData
  }

  return (
    <AuthProvider>
      <UnitUseContext.Provider value={ context }>
        <CustomDimmer visible={ isLoading } />
        { children }
      </UnitUseContext.Provider>
    </AuthProvider>
  )
}

const useUnitUseContext = () => useContext(UnitUseContext)

export { UnitUseProvider, useUnitUseContext }
