import React from 'react'
import { useChangeUnitsContext } from '../../../../contexts/ChangeUnitsContext'
import { Button } from 'semantic-ui-react'

const ChangeStatusActions = () => {
  const context = useChangeUnitsContext()

  return (
    <div className='actions'>
      {
        context.isNotAvailable() ?
          ( 
            <Button
              primary
              onClick={ context.saveAvailableState }
              loading={ context.isWorking }
              >
              Habilitar
            </Button>
          )
          : (
            <Button
              primary
              onClick={ context.saveNotAvailableState }
              loading={ context.isWorking }
            >
              Cambiar estado
            </Button>
            )
      }
    </div>
  )
}

export { ChangeStatusActions }
