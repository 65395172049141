import React from 'react'
import { Modal } from 'react-bootstrap'
import { Icon } from 'semantic-ui-react'
import {
  useAuthorizedStaysLogContext
} from '../../contexts/AuthorizedStaysLog'
import { Client } from './data_form/Client'
import { CommercialContact } from './data_form/CommercialContact'
import { CompromiseDate } from './data_form/CompromiseDate'
import { Destination } from './data_form/Destination'
import { DownloadButton } from './data_form/DownloadButton'
import { Product } from './data_form/Product'
import { Remision } from './data_form/Remision'
import { ShipmentCompany } from './data_form/ShipmentCompany'
import { StayPrice } from './data_form/StayPrice'
import { StayPriceByDay } from './data_form/StayPriceByDay'
import { StaysAuthorized } from './data_form/StaysAuthorized'
import { StaysDays } from './data_form/StaysDays'

const DataForm = () => {
  const context = useAuthorizedStaysLogContext()

  const handleClose = () => context.setSelectedAuthorization(null)

  return (
    <Modal
      show={ !!context.selectedAuthorization }
      size='lg'
      centered
      onHide={ () => {} }
    >
      <Modal.Body>
        <Icon name="close" onClick={ handleClose } />
        <h3>Detalle de la estadía</h3>
        <div className="authorized-stays-log-modal">
          <Remision/>
          <Product/>
          <Client/>
          <Destination/>
          <CommercialContact/>
          <CompromiseDate/>
          <ShipmentCompany/>
          <StaysDays/>
          <StaysAuthorized/>
          <StayPriceByDay/>
          <StayPrice/>
          <DownloadButton/>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { DataForm }
