import React from 'react'
import { Button } from 'semantic-ui-react'
import { useMotivesContext } from '../../../../contexts/MotivesContext'

const DestroyButton = () => {
  const context = useMotivesContext()

  const destroy = () => {
    context.setShowConfirmDestroy(true)
  }
  
  const canDestroy = () => context.isEditing

  return (
    <>
      {
        canDestroy() ?
        (
          <Button
            color='red'
            loading={ context.isWorking }
            onClick={ destroy }
            >
              Eliminar
          </Button>
        ) : (<></>)
      }
    </>
  ) 
}

export { DestroyButton }
