import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ScreenWithNavItems } from "../components";
import { getAuthFromStorage } from "../redux/actions";
import resources from "../resources";
import "../customStyles.css";

const Main = () => {
  
  const history = useHistory();
  const { credentials } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (credentials == null) {
      dispatch(getAuthFromStorage());
    } else if (!credentials) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);
  
  return (
    <ScreenWithNavItems
      user={credentials ? credentials.user : null}
      open={true}
      userActions={credentials ? credentials.actions : []}
    >
      <div style={styles.insideContainer}>
        <img
          src={resources.images.ergonLogo}
          alt="Logo empresa"
          style={styles.enterpriseLogo}
        />
      </div>
    </ScreenWithNavItems>
  );
};

const styles = {
  mainContainer: {
    display: "flex",
    height: "100vh",
    width: "100%",
    flexDirection: "column",
    overflow: "auto",
  },
  insideContainer: {
    display: "flex",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  enterpriseLogo: {
    width: "600px",
  },
};

export { Main };
