import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { createInputValueHandler } from '../../../../helpers/input'
import { Input } from 'semantic-ui-react'

const Model = () => {
  const context = useTanksContext()

  const InputHandler = createInputValueHandler(context.setModel)

  return (
    <div className='model-container'>
      <label className='title'>Modelo:</label>
      <Input
        type='text'
        value={ context.model || ''}
        onChange={ InputHandler }
        className='value'
      />
    </div>
  )
}

export { Model }
