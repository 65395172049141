import React, { useEffect, useState } from 'react'
import { filterdata } from '../../utils/common'
import { StyledTable } from '../StyledTable'

const tableHeaders = [
  { path: 'fecha',    title: 'Fecha' },
  { path: 'folio',    title: 'Folio' },
  { path: 'planta',   title: 'Planta' },
  { path: 'operador', title: 'Operador' },
  { path: 'telefono', title: 'Teléfono' }
]
  
const searchBy = ['folio', 'planta', 'operador','telefono']
const headerSearchBy = ['folio', 'planta', 'operador', 'teléfono']

const Table = ({ data, setData=()=>{} }) => {

  const [reload, setReload] = useState(false)
  const [orderedData, setOrderedData] = useState([])

  useEffect(() => setOrderedData(data), [data])

  const onClickHeader = (d, e) => {
    const orderedData = filterdata(d, e, reload)
    setData(orderedData)
    setReload(!reload)
  }

  return (
    <StyledTable
      headers={ tableHeaders }
      searchBy={ searchBy }
      headerSearchBy={ headerSearchBy }
      data={ orderedData }
      handleOnClickHeader={e => onClickHeader(orderedData, e) }
    />
  )
}

export { Table }
  