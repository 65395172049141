import React, { createContext, useContext, useEffect, useState } from 'react'
import { useOrdersInTransitContext } from './OrdersInTransitContext'
import {
  deletteAnObject,
  finishOrder,
  saveAnObject
} from '../services/ergonApi'
import { now } from '../helpers/date'
import { showErrorAlert, showOkAlert } from '../helpers/notifications'

const OrdersInTransitInfoModalContext = createContext()

const InfoModalProvider = ({ children }) => {
  const {
    orderDetails,
    credentials,
    selectedOrder,
    setShowOrderInfoModal,
    search
  } = useOrdersInTransitContext()

  const [tasks, setTasks] = useState(null)
  const [selectedTab, setSelectedTab] = useState('info')
  const [isDownloadingChecklist, setIsDownloadingCheckList] = useState(false)
  
  const [fechaEntregaReal,     setFechaEntregaReal    ] = useState(now())
  const [fechaSalidaRefineria, setFechaSalidaRefineria] = useState(now())
  const [fechaEntradaPlanta,   setFechaEntradaPlanta  ] = useState(now())
  const [fechaSalidaPlanta,    setFechaSalidaPlanta   ] = useState(now())
  const [plantRemarks,         setPlantRemarks        ] = useState('')
  const [boardingRemarks,      setBoardingRemarks     ] = useState('')
  const [terminationNotes,     setTerminationNotes    ] = useState('')
  
  const [isConfirming, setIsConfirming] = useState(false)
  const [showModalToFinishOrder, setShowModalToFinishOrder] = useState(false)
  const [
    showModalToFinishOrderByRealQuantity,
    setShowModalToFinishOrderByRealQuantity
  ] = useState(false)
  const [
    showModalToUnassignDriver,
    setShowModalToUnassignDriver
  ] = useState(false)
  const [selectedDetail, setSelectedDetail] = useState({})
  const [selectedDetailIndex, setSelectedDetailIndex] = useState(-1)

  const [realQuantity,          setRealQuantity]          = useState(0)
  const [remission,             setRemission]             = useState('')
  const [weight,                setWeight]                = useState(0)
  const [taraWeight,            setTaraWeight]            = useState(0)
  const [outletTemperature,     setOutletTemperature]     = useState(0)
  const [arrivalTemperature,    setArrivalTemperature]    = useState(0)
  const [compromiseTemperature, setCompromiseTemperature] = useState(0)

  useEffect(() => {
    setFechaEntregaReal('')
    setFechaSalidaRefineria('')
    setFechaEntradaPlanta('')
    setFechaSalidaPlanta('')
    setPlantRemarks('')
    setBoardingRemarks('')
    if (orderDetails?.info?.obsEmbarque)
      setBoardingRemarks(orderDetails.info.obsEmbarque)
    if (orderDetails?.info?.obsPlanta)
      setPlantRemarks(orderDetails.info.obsPlanta)
    if (orderDetails?.info?.fechaEntregaReal)
      setFechaEntregaReal(orderDetails.info.fechaEntregaReal)
    if (orderDetails?.info?.fechaSalidaRefineria)
      setFechaSalidaRefineria(orderDetails.info.fechaSalidaRefineria)
    if (orderDetails?.info?.fechaEntradaPlanta)
      setFechaEntradaPlanta(orderDetails.info.fechaEntradaPlanta)
    if (orderDetails?.info?.fechaSalidaPlanta)
      setFechaSalidaPlanta(orderDetails.info.fechaSalidaPlanta)
  }, [orderDetails])

  const handleSelectDetail = (detail, index) => {
    setSelectedDetail(detail)
    setSelectedDetailIndex(index)
    setRealQuantity(detail.cantidadReal || 0)
    setRemission(detail.remision || '')
    setWeight(detail.pesoBruto || 0)
    setTaraWeight(detail.pesoTara || 0)
    setOutletTemperature(detail.temSalida || 0)
    setArrivalTemperature(detail.temLlegada || 0)
    setCompromiseTemperature(detail.temCompromiso || 0)
  }

  const clearModal = () => {
    setRealQuantity(0)
    setRemission('')
    setWeight(0)
    setTaraWeight(0)
    setOutletTemperature(0)
    setArrivalTemperature(0)
    setCompromiseTemperature(0)
    setTasks(null)
    setSelectedTab('info')
  }

  const onSaveDetail = () => {
    orderDetails.details[selectedDetailIndex].cantidadReal = realQuantity
    orderDetails.details[selectedDetailIndex].remision     = remission
    orderDetails.details[selectedDetailIndex].pesoBruto    = weight
    orderDetails.details[selectedDetailIndex].pesoTara     = taraWeight
    orderDetails.details[selectedDetailIndex].temSalida    = outletTemperature
    orderDetails.details[selectedDetailIndex].temLlegada   = arrivalTemperature
    setSelectedDetail({})
    setSelectedDetailIndex(-1)
    clearModal()
  }

  const hasFinishableStatus   = () => (
    [
      'En transito',
      'Confirmado',
      'Con cliente',
      'En retorno'
    ].includes(orderDetails.info?.estado)
  )
  const hasSaveableStatus = () => (
    [
      'Sin Asignar',
      'En transito',
      'Por confirmar',
      'Confirmado',
      'Asignado en Espera',
      'Con cliente',
      'En retorno',
      'Terminado'
    ].includes(orderDetails.info?.estado)
  )
  const isConfirmed    = () => orderDetails.info?.estado === 'Confirmado'
  const isFinished     = () => selectedOrder.estado === 'Terminado'
  const isOrderWithoutGPSFinished = () => (
    orderDetails.info?.uSinGPS && selectedOrder.estado === 'Terminado'
  )
  const isSuperadmin   = () => credentials.user.perfil.nombre === 'superAdmin'
  const isSuperUser    = () => [1, 3].includes(credentials.user.perfil.id)
  const hasAccess      = () => credentials.actions.find(a => a.id === 8)
  const canFinishOrder = () => isSuperUser() || hasAccess()

  const isClientUnit     = () => !!orderDetails.info?.unidadSinGPS
  const isUnitWithoutGPS = () => !!orderDetails.info?.uSinGPS
  const hasRealQuantity  = () => (
    orderDetails.details.some(d => d.cantidadReal)
  )
  const isClientUnitWithRealQuantity = () => (
    (
      (isUnitWithoutGPS() && hasFinishableStatus()) ||
      isClientUnit()
    ) &&
    !isFinished() &&
    hasRealQuantity()
  )

  const getDetails = () => {
    if(!orderDetails.details) return []
    return orderDetails.details.map(d => ({
      productoPedidoId: d.id,
      producto:         d.producto,
      remision:         d.remision,
      cantidadReal:     d.cantidadReal,
      pesoBruto:        d.pesoBruto,
      pesoTara:         d.pesoTara,
      temLlegada:       d.temLlegada,
      temSalida:        d.temSalida
    }))
  }

  const buildParams = () => ({
    id: orderDetails.info.folio,
    folio: orderDetails.info.folio,
    obsEmbarque: boardingRemarks,
    obsPlanta: plantRemarks,
    detallePedido: JSON.stringify(getDetails()),
    finishOrder: isClientUnitWithRealQuantity(),
    unidadAsignada:  selectedOrder.unidadAsignada,
    fechaEntregaReal: fechaEntregaReal || null,
    fechaSalidaRefineria: fechaSalidaRefineria || null,
    fechaEntradaPlanta: fechaEntradaPlanta || null,
    fechaSalidaPlanta: fechaSalidaPlanta || null
  })

  const saveChanges = () => {
    setIsConfirming(true)
    saveAnObject(credentials.token, '/ordersInTransit/details/', buildParams())
      .then(handleSuccess)
      .catch(handleError)
      .finally(() => {
        setShowModalToFinishOrderByRealQuantity(false)
        setIsConfirming(false)
      })
  }

  const save = () => {
    if (isClientUnitWithRealQuantity())
      return setShowModalToFinishOrderByRealQuantity(true)
    saveChanges()
  }

  const unassignDriver = () => {
    setIsConfirming(true)
    const url = '/pedido/cancelarConfirmacion/'
    deletteAnObject(credentials.token, url, selectedOrder.folio)
      .then(response => {
        setShowModalToUnassignDriver(false)
        handleSuccess(response)
      })
      .catch(handleError)
      .finally(() => setIsConfirming(false))
  }

  const onConfirmFinishOrder = () => {
    setIsConfirming(true)
    const url = '/ordersInTransit/finishOrder/'
    const params = { terminationNotes }
    finishOrder(credentials.token, url, params, selectedOrder.folio)
      .then(response => {
        setShowModalToFinishOrder(false)
        handleSuccess(response)
      })
      .catch(handleError)
      .finally(() => setIsConfirming(false))
  }

  const handleSuccess = response => {
    if (response.errorCode) return showErrorAlert(response.message)
    showOkAlert(response?.message)
    setShowOrderInfoModal(false)
    clearModal()
    search()
  }

  const handleError = error => showErrorAlert(error?.message)

  const isDeleted = () => !!selectedOrder?.borrado

  const context = {
    tasks,                  setTasks,
    selectedTab,            setSelectedTab,
    isDownloadingChecklist, setIsDownloadingCheckList,
    fechaEntregaReal,       setFechaEntregaReal,
    fechaSalidaRefineria,   setFechaSalidaRefineria,
    fechaEntradaPlanta,     setFechaEntradaPlanta,
    fechaSalidaPlanta,      setFechaSalidaPlanta,
    plantRemarks,           setPlantRemarks,
    boardingRemarks,        setBoardingRemarks,
    realQuantity,           setRealQuantity,
    remission,              setRemission,
    weight,                 setWeight,
    taraWeight,             setTaraWeight,
    outletTemperature,      setOutletTemperature,
    arrivalTemperature,     setArrivalTemperature,
    compromiseTemperature,
    selectedDetail,
    showModalToUnassignDriver, setShowModalToUnassignDriver,
    isConfirming,
    showModalToFinishOrder, setShowModalToFinishOrder,
    showModalToFinishOrderByRealQuantity, setShowModalToFinishOrderByRealQuantity,
    setTerminationNotes,

    handleSelectDetail,
    onSaveDetail,
    save,
    saveChanges,
    clearModal,
    canFinishOrder,
    hasFinishableStatus,
    isConfirmed,
    isOrderWithoutGPSFinished,
    hasSaveableStatus,
    isSuperadmin,
    unassignDriver,
    isClientUnit,
    onConfirmFinishOrder,
    isUnitWithoutGPS,
    isDeleted
  }

  return (
    <OrdersInTransitInfoModalContext.Provider value={context}>
      { children }
    </OrdersInTransitInfoModalContext.Provider>
  )
}

const useOrdersInTransitInfoModalContext = () => (
  useContext(OrdersInTransitInfoModalContext)
)

export { InfoModalProvider, useOrdersInTransitInfoModalContext }
