import React from 'react'
import { Input } from 'semantic-ui-react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { createInputValueHandler } from '../../../../helpers/input'

const VIN = () => {
  const context = useTanksContext()

  const InputHandler = createInputValueHandler(context.setVIN)

  return (
    <div className='vin-container'>
      <label className='title'>Número NIV:</label>
      {
        context?.canEditVIN ?
        (
          <Input
            value={ context.VIN || ''}
            onChange={ InputHandler }
            className='value'
          />
        ) :
        (
          <Input
            disabled
            type='text'
            value={ context.VIN || ''}
            onChange={ InputHandler }
            className='value'
          />
        )
      }
    </div>
  )
}

export { VIN }
