import React from 'react'
import { StyledTable } from '../StyledTable'
import { useVehicleContext } from '../../contexts/vehicleContext'
import { ExcelButton } from '../ExcelButton'

const headers = [
  { path: 'economico',           title: 'Económico'     },
  { path: 'placas',              title: 'Placas Unidad' },
  { path: 'placasTanque',        title: 'Placas Tanque' },
  { path: 'transportistaNombre', title: 'Transportista' }
]

const toSearch = headers.map(h => h.path)

const Table = () => {
  const context = useVehicleContext()
  return (
    <div className='vehicles-screen-table-container'>
      <div className='vehicles-excel-button'>
        <ExcelButton
          endpoint='/generalUnit/download_excel'
          fileName='Unidades'
        />
      </div>
      <div className='table'>
        <StyledTable
          search
          data={ context.vehicles }
          headers={ headers }
          searchBy={ toSearch }
          handleOnClickRow={ context.onSelectVehicle }
        />
      </div>
    </div>
  )
}

export { Table }
