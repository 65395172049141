import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ScreenWithNavItems,
  StyledTable,
  RadioButtonGroup,
  ModalToElimate,
  CustomDimmer
} from "../components";
import { Input, Dropdown, TextArea, Form, Button } from "semantic-ui-react";
import { getAuthFromStorage } from "../redux/actions";
import {
  getProducts,
  saveProduct,
  deleteProduct,
  getAvailableStatus,
  getWeightUnits,
} from "../services/ergonApi";
import "../customStyles.css";
import {
  parseArrayObjectsToDropdownArray,
  Toast,
  Swal1,
  filterdata,
} from "../utils/common";

const Product = () => {

  const history = useHistory();
  const { credentials } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [status, setStatus] = useState([]);
  const [weightUnits, setWeightUnits] = useState([]);
  const [idProduct, setIdProduct] = useState(null);
  const [name, setName] = useState("");
  const [selectedWeightUnit, setSelectedWeightUnit] = useState(null);
  const [notes, setNotes] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [sendingData, setSendingData] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [reloadDataOfTable, setReloadDataOfTable] = useState(false);
  const [loadingDimmer, setLoadingDimmer] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (credentials == null) {
      dispatch(getAuthFromStorage());
    } else if (!credentials) {
      history.push("/");
    } else {
      setLoadingDimmer(true)
      fetchWeightUnits();
      fetchProducts();
      fetchStatusTypes();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);
  useEffect(() => {
    loadTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadDataOfTable]);

  async function fetchProducts() {
    let response = await getProducts(credentials.token);
    if (response.errorCode) {
      Swal1.fire({
        title: response.message,
      });
    } else {
      setProducts(response);
    }
    setLoadingDimmer(false)
  }
  async function fetchWeightUnits() {
    let response = await getWeightUnits(credentials.token);
    if (response.errorCode) {
      Swal1.fire({
        title: response.message,
      });
    } else {
      response = parseArrayObjectsToDropdownArray(response, "nombre");
      setWeightUnits(response);
    }
  }
  async function fetchStatusTypes() {
    let statusTypesResponse = await getAvailableStatus(credentials.token);
    if (statusTypesResponse.errorCode) {
      Swal1.fire({
        title: statusTypesResponse.message,
      });
    } else {
      setStatus(statusTypesResponse);
      setSelectedStatus(statusTypesResponse[0]);
    }
  }

  async function handleButtonClick() {
    setSendingData(true);
    let obj = {
      id: idProduct,
      nombre: name,
      estado: selectedStatus?.id,
      unidadMedida: selectedWeightUnit?.id,
      notas: notes,
    };
    let response = await saveProduct(credentials.token, obj);
    if (!response.errorCode) {
      Toast.fire({
        title: response.message,
      });
    } else {
      Swal1.fire({
        title: response.message,
      });
    }
    await fetchProducts();
    if (!response.errorCode) {
      setEditValues({
        id: null,
        nombre: "",
        estado: status[0],
        unidadMedida: null,
        notas: "",
      });
    }
    setSendingData(false);
  }
  async function handleDeleteButtonClick() {
    setSendingData(true);
    let response = await deleteProduct(credentials.token, idProduct);
    setShowDeleteConfirmation(false);
    if (!response.errorCode) {
      Toast.fire({
        title: response.message,
      });
    } else {
      Swal1.fire({
        title: response.message,
      });
    }
    await fetchProducts();
    setEditValues({
      id: null,
      nombre: "",
      estado: status[0],
      unidadMedida: null,
      notas: "",
    });
    setSendingData(false);
  }
  function setEditValues(data) {
    setIdProduct(data.id);
    setName(data.nombre);
    setSelectedWeightUnit(data.unidadMedida);
    setNotes(data.notas);
    setSelectedStatus(data.estado);
  }
  const cleanForms = () => {
    setEditValues({
      id: null,
      nombre: "",
      estado: status[0],
      unidadMedida: null,
      notas: "",
    });
  };
  function handleFilterData(data, e) {
    setProducts(filterdata(data, e, reloadDataOfTable));
    setReloadDataOfTable(!reloadDataOfTable);
  }
  function loadTable() {
    return (
      <StyledTable
        headers={[
          { path: "nombre", title: "Nombre" },
          { path: "unidadMedida.nombre", title: "Unidad Medida" },
          { path: "estado.nombre", title: "Estado" },
        ]}
        searchBy={["nombre","unidadMedida.nombre","estado.nombre"]}
        headerSearchBy={["Nombre del producto", "Unidad Medida","Estado"]}
        data={products}
        handleOnClickRow={(data) => setEditValues(data)}
        handleOnClickHeader={(e) => handleFilterData(products, e)}
      />
    );
  }
  return (
    <ScreenWithNavItems user={credentials ? credentials.user : null}>
      <span style={styles.titleText}>Productos</span>
      <div style={styles.mainContainer}>
        <ModalToElimate
          title="Confirmación para eliminar producto"
          textContent="Una vez eliminada la información no se podrá recuperar. ¿Está seguro que desea eliminar este producto?"
          showModal={showDeleteConfirmation}
          handleOnClickCancel={() => setShowDeleteConfirmation(false)}
          handleOnClickConfirm={() => handleDeleteButtonClick()}
        />
        <CustomDimmer visible={loadingDimmer}/>
        <div style={styles.formContainer}>
          <Form size="mini">
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Nombre: </span>
              <Input
                placeholder="Nombre"
                value={name}
                onChange={(e, { value }) => setName(value)}
                style={{ width: "100%", margin: 0 }}
              />
            </div>  
            <div style={styles.formLine2}>
              <span style={styles.subtitleTextTipo}> Estado: </span>
              <RadioButtonGroup
                options={status}
                selectedValue={selectedStatus}
                onSelectoption={(value) => setSelectedStatus(value)}
                labelKey="nombre"
              />
            </div>
            <div style={styles.formLine}>
              <span style={styles.subtitleText}> Unidad Medida: </span>
              <Dropdown
                placeholder="Seleccione una unidad de medida"
                value={selectedWeightUnit}
                fluid
                selection
                search
                options={weightUnits}
                onChange={(e, data) => setSelectedWeightUnit(data.value)}
              />
            </div>
            <div style={styles.formLine}>
            <span style={styles.subtitleText}> Notas : </span>
            <TextArea
            placeholder="Notas"
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
              style={{ width: "100%", margin: 0 }}
            />
          </div>
          </Form>
          
          <div style={styles.buttonContainer}>
            {idProduct ? (
              <Button
                color="grey"
                loading={sendingData}
                style={{ marginRight: 10, fontSize: 12 }}
                onClick={() => cleanForms()}
              >
                Cancelar cambios
              </Button>
            ) : (
              <></>
            )}
            {idProduct ? (
              <Button
                color="red"
                loading={sendingData}
                style={{ marginRight: 10, fontSize: 12 }}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Eliminar producto
              </Button>
            ) : (
              <></>
            )}
            <Button
              primary
              loading={sendingData}
              style={{ margin: 0, fontSize: 12 }}
              onClick={() => handleButtonClick()}
            >
              {" "}
              {idProduct ? "Guardar cambios" : "Guardar"}
            </Button>
          </div>
        </div>

        <div style={styles.tableContainer}>{loadTable()}</div>
      </div>
    </ScreenWithNavItems>
  );
};

const styles = {
  mainContainer: {
    display: "flex",
    height: "90%",
    width: "100%",
    padding: 10,
    justifyContent: "space-between",
  },
  titleText: {
    fontSize: "3rem",
    fontWeight: "400",
  },
  formContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    width: "45%",
    padding: 10,
    borderRadius: 5,
  },
  formLine: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  subtitleText: {
    width: "30%",
    fontSize: 12,
    textAlign: "right",
    marginRight: 10,
  },
  tableContainer: {
    overflow: "auto",
    display: "flex",
    height: "90%",
    width: "50%",
    padding: 10,
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 5,
  },
  formLine2: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  subtitleTextTipo: {
    width: "30%",
    fontSize: 12,
    textAlign: "right",
    marginRight: 5,
  },
};

export { Product };
