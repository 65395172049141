import React from 'react';


export const DataPanel = ({ value, title, size=130 }) => (
  <div
  style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: size,
    height: size,
    backgroundColor: "#c4c4c4",
  }}
>
  <span style={{ fontSize: "2.5rem" }}>{value}</span>
  <span>{title}</span>
</div>
);
