import React from 'react'
import { useTanksContext } from '../../../../contexts/TanksContext'
import { ShipmentCompaniesDropdown } from '../../../ShipmentCompaniesDropdown'

const Carrier = () => {
  const context = useTanksContext()

  return (
    <div className='carrier-container'>
      <label className='title'>Transportista:</label>
      <ShipmentCompaniesDropdown
        value={ context.carrierId }
        setSelectedCompany={ context.setCarrierId }
      />
    </div>
  )
}

export { Carrier }
