import React from 'react'
import { useIncidencesContext } from '../../../contexts/IncidencesContext'
import { createInputValueHandler } from '../../../helpers/input'
import { Input } from 'semantic-ui-react'

const DescriptionIncidence = () => {
  const context = useIncidencesContext()
  const description = createInputValueHandler(context.setDescription)
  
  return (
    <div className='form-field'>
      <label>Descripción:</label>
      <Input
        value={ context.description || ''}
        placeholder={'Nombre de incidencia'}
        onChange={ description }
      />
    </div>
  )
}

export { DescriptionIncidence }
