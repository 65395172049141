import React from 'react'
import { DataPanel } from '../DataPanel'

const Totals = ({ orders = {} }) => {

  const getDatapanelsData = () => (
    [
      { title: 'Por liberar',   value: orders.pendingRelease },
      { title: 'Por Asignar',   value: orders.pendingAssign },
      { title: 'Por Confirmar', value: orders.pendingConfirm },
      { title: 'Confirmados',   value: orders.confirmed },
      { title: 'En tránsito',   value: orders.inTransit },
      { title: 'Con cliente',   value: orders.withClient },
      { title: 'En retorno',    value: orders.returning }
    ]
  )

  return (
    getDatapanelsData().map(t => (
      <DataPanel title={ t.title } value={ t.value } size={ 100 } />
    ))
  )
}

export { Totals }
