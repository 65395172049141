import React from 'react'
import { DataForm } from '../components/authorized_stays_log/DataForm'
import { SearchBar } from '../components/authorized_stays_log/SearchBar'
import { Table } from '../components/authorized_stays_log/Table'
import { AuthorizedStaysLogProvider } from '../contexts/AuthorizedStaysLog'

const AuthorizedStaysLog = () => {
  return (
    <AuthorizedStaysLogProvider>
      <h1>Reporte de estadías autorizadas</h1>
      <div className="screen-authorized-stays-log">
        <div>
          <SearchBar/>
          <Table/>
          <DataForm/>
        </div>
      </div>
    </AuthorizedStaysLogProvider>
  )
}

export { AuthorizedStaysLog }
