import React from 'react'
import { useUploadEvidenceContext } from '../../contexts/UploadEvidenceContext'
import { Button } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'

const ReactivateConfirmationModal = () => {
  const context = useUploadEvidenceContext()

  const cancelHandler = () => {
    context.setShowConfirmReactivate(false)
  }

  const confirmHandler = () => {
    context.reactivateUnit()
    context.setShowConfirmReactivate(false)
  }
 
  return (
    <Modal 
      show={ context.showConfirmReactivate }
      className='reactivate-confirmation-modal'
    >
      <Modal.Header>
        <Modal.Title>
          Confirmación de solicitud para reactivar unidad
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Está seguro que desea solicitar reactivar la unidad?
      </Modal.Body>
      <Modal.Footer>
        <Button
          color='grey'
          loading={ context.isWorking }
          onClick={ cancelHandler }
        >
          Cancelar
        </Button>
        <Button
          color='blue'
          className='reactivate-button'
          loading={ context.isWorking }
          onClick={ confirmHandler }
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ReactivateConfirmationModal }
