import React, { useEffect, useState } from 'react'
import { Swal1, Toast } from '../utils/common'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CustomDimmer, ScreenWithNavItems } from '../components'
import { getAuthFromStorage } from '../redux/actions'
import { deletteAnObject, getGeneralInfo, saveAnObject } from '../services/ergonApi'
import { CustomModalToFinishOrder } from '../components/CustomModalToFinishOrder.js'
import { CustomModalToUnassignOrder } from '../components/CustomModalToUnassignOrder.js'
import { FinishOrderButton } from '../components/order_finish/FinishOrderButton'
import { UnasignOrderButton } from '../components/order_finish/UnasignOrderButton'
import { Table } from '../components/order_finish/Table'
import { MainForm } from '../components/order_finish/MainForm'

const getLabel = u => (
  `Economico: ${u?.economico} | Estado: ${u?.state} | Operador: ${u?.driver}`
)

export const OrderFinish = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { credentials } = useSelector((state) => state.auth)

  const [loadingDimmer,                setLoadingDimmer]                = useState(true)
  const [showFinishTaskConfirmation,   setShowFinishTaskConfirmation]   = useState(false)
  const [showUnassignTaskConfirmation, setShowUnassignTaskConfirmation] = useState(false)
  const [units,                        setUnits]                        = useState([])
  const [selectedUnit,                 setSelectedUnit]                 = useState({})
  const [orders,                       setOrders]                       = useState([])
  const [orderSelected,                setOrderSelected]                = useState(0)

  useEffect(() => {
    if (credentials == null)
      dispatch(getAuthFromStorage())
    else if (!credentials)
      history.push("/")
    else
      load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const load = async () => {
    const path = '/finishOrders/units'
    const unitsResponse = await getGeneralInfo(credentials.token, path)
    setUnits(unitsResponse)
    setLoadingDimmer(false)
  }

  const options = () => (
    units.map(u => ({
      id: u.id,
      key: u.id,
      text: getLabel(u),
      value: u.id
    }))
  )

  const handleFinishOrder = () => {
    setShowFinishTaskConfirmation(false)
    setLoadingDimmer(true)
    const params = { id: orderSelected }
    saveAnObject(credentials.token, `/finishOrders/`, params)
      .then(response => {
        Toast.fire({ title: response.message })
        handleChangeUnit(selectedUnit.id)
      })
      .catch(error => {
        console.error(error)
        Swal1.fire({ title: 'Ocurrió un error al intentar realizar esta operación.' })
      })
      .finally(() => setLoadingDimmer(false))
  }

  const handleUnassignOrder = () => {
    setShowUnassignTaskConfirmation(false)
    setLoadingDimmer(true)
    deletteAnObject(credentials.token, '/pedido/cancelarConfirmacion/', orderSelected)
      .then(response => {
        Toast.fire({ title: response.message })
        handleChangeUnit(selectedUnit.id)
      })
      .catch(error => {
        console.error(error)
        Swal1.fire({ title: 'Ocurrió un error al intentar realizar esta operación.' })
      })
      .finally(() => setLoadingDimmer(false))
  }

  const getFinishOrderButton = order => (
    <FinishOrderButton
      onClick={() => {
        setOrderSelected(order.folio)
        setShowFinishTaskConfirmation(true)
      }}
    />
  )

  const getUnassignOrderButton = order => (
    <UnasignOrderButton
      order={ order }
      onClick={() => {
        setOrderSelected(order.folio)
        setShowUnassignTaskConfirmation(true)
      }}
    />
  )

  const formatOrder = o => ({
    folio:           o.folio,
    fechaPedido:     o.fechaPedido,
    fechaCarga:      o.fechaCarga,
    fechaCompromiso: o.fechaCompromiso,
    estado:          o.estado,
    action:          getFinishOrderButton(o),
    unassign:        getUnassignOrderButton(o)
  })

  const findUnit = id => units.find(u => u.id === id)

  const handleChangeUnit = id => {
    setSelectedUnit(findUnit(id))
    getGeneralInfo(credentials.token, `/finishOrders/orders/${id}`)
      .then(response => setOrders(response.map(formatOrder)))
      .catch(console.error)
  }

  return (
    <ScreenWithNavItems user={credentials ? credentials.user : null}>
      <CustomDimmer visible={loadingDimmer} />
      <CustomModalToFinishOrder
        showModal={showFinishTaskConfirmation}
        handleOnClickCancel={() => setShowFinishTaskConfirmation(false)}
        handleOnClickConfirm={() => handleFinishOrder()}
      />
      <CustomModalToUnassignOrder
        showModal={showUnassignTaskConfirmation}
        handleOnClickCancel={() => setShowUnassignTaskConfirmation(false)}
        handleOnClickConfirm={() => handleUnassignOrder()}
      />
      <div className='screen-order-finish'>
        <h1>Terminación de pedidos</h1>
        <MainForm
          options={ options() }
          onSelectUnit={ handleChangeUnit }
          selectedUnit={ selectedUnit }
        />
        <hr />
        <Table
          orders={ orders }
          hasFinishableOrders={ selectedUnit.id }
        />
      </div>
    </ScreenWithNavItems>
  )
}
